import DialogueModal from 'components/Dialogue/DialogueModal'
import { TDappData } from 'constants/settings/types/TNpcs'
import React, { useState } from 'react'
import TokenExchange from './TokenExchange'

interface TokenExchangeModalProps {
  dappData: TDappData
}

export default function TokenExchangeModal({ dappData }: TokenExchangeModalProps) {
  // State to manage which modal to show
  const [showModal, setShowModal] = useState(true)

  // Close modal functions
  const closeModal = () => setShowModal(false)

  const modalShowEl = showModal ? (
    <DialogueModal isOpen={showModal} onClose={closeModal} title="Token Exchange">
      <TokenExchange dappData={dappData} isOpen={showModal} onDismiss={closeModal} />
    </DialogueModal>
  ) : (
    <></>
  )

  return modalShowEl
}
