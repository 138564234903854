import React, { useState, useEffect, ReactChild } from 'react'
import {
  Container,
  OverlayBox,
  Letter,
  DialogueBox,
  DialogueOverlay,
  CloseIcon,
  CloseButton
} from './IconWithModal.styles'

interface IconWithModalProps {
  IconComponent: React.FC // Custom icon component (e.g., MapIcon, GearIcon)
  triggerKey: string // Key to open the modal (e.g., 'B', 'G')
  overlayLetter: string // Letter to display in OverlayBox
  modalTitle: string // Modal title
  modalContent: ReactChild // Modal content (text, buttons, etc.)
  letterPressed?: boolean // Optional to animate the letter
}

export const IconWithModal: React.FC<IconWithModalProps> = ({
  IconComponent,
  triggerKey,
  overlayLetter,
  modalTitle,
  modalContent,
  letterPressed = false
}) => {
  const [isClicked, setIsClicked] = useState(false)
  const [keyPressed, setKeyPressed] = useState(letterPressed)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleClick = () => {
    if (!isModalOpen) {
      setIsModalOpen(true)
      setIsClicked(true)
    }
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const handleKeyPress = (event: KeyboardEvent) => {
    if (!isModalOpen && event.key.toLowerCase() === triggerKey.toLowerCase()) {
      setIsModalOpen(true)
      setKeyPressed(true)
    }
    if (isModalOpen && event.key.toLowerCase() === 'enter') {
      closeModal()
    }
  }

  useEffect(() => {
    window.addEventListener('keypress', handleKeyPress)
    return () => {
      window.removeEventListener('keypress', handleKeyPress)
    }
  }, [isModalOpen])

  return (
    <Container isClicked={isClicked} onClick={handleClick}>
      <IconComponent />
      <OverlayBox keyPressed={keyPressed}>
        <Letter>{overlayLetter}</Letter>
      </OverlayBox>

      {isModalOpen && (
        <>
          <DialogueOverlay onClick={closeModal} />
          <DialogueBox>
            <CloseIcon onClick={closeModal}>{`[Esc] / x`}</CloseIcon>
            {modalTitle !== '' && <h2>{modalTitle}</h2>}
            {modalContent}
            <CloseButton onClick={closeModal}>Close</CloseButton>
          </DialogueBox>
        </>
      )}
    </Container>
  )
}
