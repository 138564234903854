import { TSettings, EBackgroundTheme } from 'constants/settings/types/TSettings'
import backgroundImg2 from '../../../assets/images/application/background.png'
import logoWhite from '../../../assets/images/application/logo-2.png'
import logoDark from '../../../assets/images/application/logo-2.png'
import cardBGImageSmaller from '../../../assets/images/application/xl_uni.png'
import { ChainId, Blockchain } from '../index'
import { Token, Currency } from '@/lib/sdk/index'
import { ChainTokenList } from '@/lib/sdk-extra/chainTokenList'

const chainId = ChainId.BASE

const wrappedCurrency = new Token(chainId, '0x4200000000000000000000000000000000000006', 18, 'WETH', 'Wrapped Ether')
const chainTokenList = new ChainTokenList(chainId)

// BASE chain settings
export const BASE: TSettings = {
  platformBackgroundTheme: EBackgroundTheme.STAR,
  platformName: 'DEFI RPG',
  platformLinks: [
    { label: 'Visit the Discord', url: 'https://discord.gg/nm2ayGSrZV' },
    { label: 'Check us out on X', url: 'https://x.com/defi_rpg' },
    { label: 'Telegram Group', url: 'https://t.me/DeFi_RPG' },
    { label: 'Reddit', url: 'https://www.reddit.com/r/DeFiRPG/s/H8VFbMtAcF' }
  ],
  platformImg: {
    appBgImg: backgroundImg2,
    appLogoWhite: logoWhite,
    appLogoDark: logoDark,
    appBgCard: cardBGImageSmaller
  },
  webInterfaces: ['defiapps.io'],
  blockchainSettings: {
    chainId: chainId,
    blockchain: Blockchain.BASE,
    name: 'Base',
    rpcURLs: ['https://base.publicnode.com/'],
    explorerURL: 'https://basescan.org/',
    blockTime: 2,
    hexChainId: `0x${chainId?.toString(16)}`,
    currency: new Currency(18, 'ETH', 'Ether')
  },
  etherscanPrefix: 'https://basescan.org',
  liquidityPair: {
    symbol: 'RPG-LP',
    name: 'RPG-LP Token'
  },
  multiCallAddr: '0xcA11bde05977b3631167028862bE2a173976CA11',
  wrappedCurrency: wrappedCurrency,
  suggestedBases: [],
  pinnedPairs: [],
  merkleDistributorAddr: undefined,
  chainTokenList: chainTokenList,
  basesToTrackLiquidityFor: [],
  basesToCheckTradesAgainst: []
}
