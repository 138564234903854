import { TTiles } from '../types/TTiles'
import tile1 from './assets/tiles/1.png'
import tile10 from './assets/tiles/10.png'
import tile11 from './assets/tiles/11.png'
import tile12 from './assets/tiles/12.png'
import tile13 from './assets/tiles/13.png'
import tile14 from './assets/tiles/14.png'
import tile15 from './assets/tiles/15.png'
import tile16 from './assets/tiles/16.png'
import tile17 from './assets/tiles/17.png'
import tile18 from './assets/tiles/18.png'
import tile19 from './assets/tiles/19.png'
import tile2 from './assets/tiles/2.png'
import tile20 from './assets/tiles/20.png'
import tile21 from './assets/tiles/21.png'
import tile3 from './assets/tiles/3.png'
import tile4 from './assets/tiles/4.png'
import tile5 from './assets/tiles/5.png'
import tile6 from './assets/tiles/6.png'
import tile7 from './assets/tiles/7.png'
import tile8 from './assets/tiles/8.png'
import tile9 from './assets/tiles/9.png'

export const tiles: TTiles = {
  tileImageMappings: {
    '0': {
      imageUrl: tile12,
      blockable: true
    },
    '1': {
      imageUrl: tile1,
      blockable: true
    },
    '2': {
      imageUrl: tile2,
      blockable: true
    },
    '3': {
      imageUrl: tile3,
      blockable: true
    },
    '4': {
      imageUrl: tile4,
      blockable: true
    },
    '5': {
      imageUrl: tile5,
      blockable: true
    },
    '6': {
      imageUrl: tile6,
      blockable: true
    },
    '7': {
      imageUrl: tile7,
      blockable: true
    },
    '8': {
      imageUrl: tile8,
      blockable: true
    },
    '9': {
      imageUrl: tile9,
      blockable: true
    },
    '10': {
      imageUrl: tile10,
      blockable: true
    },
    '11': {
      imageUrl: tile11,
      blockable: true
    },
    '12': {
      imageUrl: tile12,
      blockable: false
    },
    '13': {
      imageUrl: tile13,
      blockable: true
    },
    '14': {
      imageUrl: tile14,
      blockable: true
    },
    '15': {
      imageUrl: tile15,
      blockable: true
    },
    '16': {
      imageUrl: tile16,
      blockable: false
    },
    '17': {
      imageUrl: tile17,
      blockable: false
    },
    '18': {
      imageUrl: tile18,
      blockable: false
    },
    '19': {
      imageUrl: tile19,
      blockable: false
    },
    '20': {
      imageUrl: tile20,
      blockable: false
    },
    '21': {
      imageUrl: tile21,
      blockable: false
    }
  }
}
