import React from 'react'
import { GearIcon } from '../Icons/Icons'
import { IconWithModal } from '../IconWithModal/IconWithModal'
import { ControlsInstructions } from '../MovementInstructions/MovementInstructions'

export const SettingIcon: React.FC = () => (
  <IconWithModal
    IconComponent={GearIcon}
    triggerKey="g"
    overlayLetter="G"
    modalTitle=""
    modalContent={<ControlsInstructions />}
  />
)
