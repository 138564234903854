//needs to reference from the game.tsx file
import { ChainId } from '..'
import { EDialogueOptionType, ENPCType, NPC } from '../types/TNpcs'
import farmerSprite from './assets/npc/farmer.png'
import lizardMage from './assets/npc/lizardmage.png'
import mayorSprite from './assets/npc/mayor.png'
import robotSprite from './assets/npc/robot.png'
import spaceCowboySprite from './assets/npc/space_cowboy.png'
import traderSprite from './assets/npc/trader.png'
import { partnerNPCs } from './partnerNPCs'
import { Token } from '@/lib/sdk/index'

const chainId = ChainId.BASE
const farmAddress = '0x3713E9D0D4b6CDaA5F5AabD5c98a29877323581b'
const factoryAddress = '0xB8364EFF247a469fe7b34956B1e5c0A9Bda6F630'
const initHash = '0x57c454d7cd80b7071ad4cf9f163c6e63b2e29ed0f61e3c967e0e528664728ae5'
const gameToken = new Token(chainId, '0xFB6717742009E2cFD5a66186c939AC892c2A8654', 18, 'Game Token', 'GAME')
const spaceToken = new Token(chainId, '0x8dD806Cd8e5349A96103dc1525bb07aa0bf13dae', 18, 'Space Token', 'SPACE')
const govTokenName = spaceToken.symbol

export const npcs: NPC[] = [
  // mayor
  {
    id: '0',
    type: ENPCType.NPC,
    belongsToChainId: chainId,
    spritesheetUrl: mayorSprite,
    frameWidth: 48,
    frameHeight: 48,
    spritesheetNumRows: 9,
    spritesheetNumColumns: 4,
    startingLocation: [360, 575],
    moveToLocation: [360, 630],
    npcBehavior: { continuousMovement: true, speed: 1 },
    idleAnimation: {
      enabled: true,
      intervalOrRandom: 'interval'
    },
    spriteRows: {
      default: 0,
      down: 0,
      left: 1,
      up: 2,
      moveDown: 3,
      moveLeft: 4,
      moveUp: 5
    },
    hasLeftFacingSprites: false,
    dialogue: {
      speedTextMS: 20,
      npcName: 'Mayor',
      text:
        "Greetings, Adventurer! I'm the Mayor here in DeFi RPG's BASE settlement. Our settlement is small now, but we're actively expanding and building out this corner of the blockchain. I'm here to provide you with official information, guidance, and a vision of what's to come! As we grow, we'll be adding new characters, quests, and features. Stick around, because this is just the beginning!",
      options: [
        {
          menuDescription: 'Read the White Papers',
          followupText:
            "Ah, a scholar in our midst! These white papers will provide insight into our foundation and vision. They're evolving documents, so expect more pages to be added as the settlement expands. Would you like to open the latest edition?",
          dappData: {
            externalLinkUrl:
              'https://docs.google.com/document/d/1JFGSYNFCHUTKx52Lb5msXlrcnFu6UV1kRpZkS3WW5fM/edit?usp=sharing'
          },
          type: EDialogueOptionType.EXTERNAL_LINK,
          modalTitle: ''
        },
        {
          menuDescription: 'Tell me more about DeFi RPG',
          followupText:
            "DeFi RPG is more than just a platform—it's an adventure! Our settlements are still budding, but we're expanding across multiple blockchains, each with its own unique theme and opportunities. For the latest alpha, check out our Discord and get active in the community! Your role in this adventure will shape the land. Every choice you make matters!",

          type: EDialogueOptionType.TEXT_ONLY,
          modalTitle: '',
          dappData: {}
        },
        {
          menuDescription: 'Is this the only game/blockchain?',
          followupText: `Not for long! Our settlers are already planning expansions to other blockchains, like the sea-themed CRONOS settlement! Each settlement will have its own Mayor, unique NPCs, and blockchain-specific partnerships. But don't worry, your ${govTokenName} tokens will directly affect this chain and some multi-chain decisions! We're building an interconnected, multi-chain world where your adventures span across the stars.`,

          type: EDialogueOptionType.TEXT_ONLY,
          modalTitle: '',
          dappData: {}
        },
        {
          menuDescription: 'Where can I find real-time updates?',
          followupText:
            "The Space Cowboy's your go-to for all the latest news! He's got connections to all our social channels and will keep you up to date on expansions, partnerships, and community events.",

          type: EDialogueOptionType.TEXT_ONLY,
          modalTitle: '',
          dappData: {}
        },
        {
          menuDescription: 'Game Farms (Legacy)',
          dappData: {
            farmAddress: '0x1e4E04e8638AaaCFD5E42893D0989FED84ed6cCA',
            factoryAddress: factoryAddress,
            initHash: initHash,
            governanceToken: gameToken,
            routerAddress: '0x236D14CbE83d5f3E92f01952A2FAB25174adcb9e',
            appTokenImage: 'https://basescan.org/token/images/weth_28.png'
          },
          type: EDialogueOptionType.FARM,
          modalTitle: `${govTokenName} Farms`
        }
      ]
    }
  },
  // trader
  {
    id: '1',
    type: ENPCType.NPC,
    belongsToChainId: chainId,
    spritesheetUrl: traderSprite,
    frameWidth: 48,
    frameHeight: 48,
    spritesheetNumRows: 9,
    spritesheetNumColumns: 4,
    startingLocation: [360, 500],
    moveToLocation: [420, 500],
    npcBehavior: { continuousMovement: false, speed: 1 },
    idleAnimation: {
      enabled: true,
      intervalOrRandom: 'random'
    },
    spriteRows: {
      default: 0,
      down: 0,
      left: 1,
      up: 2,
      moveDown: 3,
      moveLeft: 4,
      moveUp: 5
    },
    hasLeftFacingSprites: false,
    dialogue: {
      npcName: 'Trader',
      text:
        "Well, if it ain't a new face! I'm the Trader here at the Exchange. Need to swap your tokens or dive into some deep liquidity pools? I've got you covered, stranger. Just remember—every trade comes with a little risk and a whole lot of opportunity.",
      options: [
        {
          menuDescription: 'Visit Swaps',
          dappData: {
            farmAddress: farmAddress,
            factoryAddress: factoryAddress,
            initHash: initHash,
            governanceToken: spaceToken,
            routerAddress: '0x236D14CbE83d5f3E92f01952A2FAB25174adcb9e',
            appTokenImage: 'https://basescan.org/token/images/weth_28.png'
          },
          type: EDialogueOptionType.SWAP,
          modalTitle: `${govTokenName} Swaps`
        },
        {
          menuDescription: 'Check Liquidity Pools',
          dappData: {
            farmAddress: farmAddress,
            factoryAddress: factoryAddress,
            initHash: initHash,
            governanceToken: spaceToken,
            routerAddress: '0x236D14CbE83d5f3E92f01952A2FAB25174adcb9e',
            appTokenImage: 'https://basescan.org/token/images/weth_28.png'
          },
          type: EDialogueOptionType.POOLS,
          modalTitle: `${govTokenName} Pools`
        },
        {
          menuDescription: 'Import Pools',
          dappData: {
            farmAddress: farmAddress,
            factoryAddress: factoryAddress,
            initHash: initHash,
            governanceToken: spaceToken,
            routerAddress: '0x236D14CbE83d5f3E92f01952A2FAB25174adcb9e',
            appTokenImage: 'https://basescan.org/token/images/weth_28.png'
          },
          type: EDialogueOptionType.IMPORT_POOLS,
          modalTitle: 'Import Pools'
        },
        {
          menuDescription: "What's a Swap?",
          followupText:
            "Swapping's simple, really. You trade one token for another. It's like bartering... but with smart contracts doing the heavy lifting! Different tokens have different values, so keep an eye on those numbers before making your move.",

          type: EDialogueOptionType.TEXT_ONLY,
          modalTitle: '',
          dappData: {}
        },
        {
          menuDescription: 'How do I join a Liquidity Pool?',
          followupText:
            "It's straightforward! Combine two tokens of equal value to create a liquidity pool (LP) token. You'll need these to join pools, earn fees, or even stake in the Farms. The Farmer can help you out with that next step.",

          type: EDialogueOptionType.TEXT_ONLY,
          modalTitle: '',
          dappData: {}
        }
      ]
    }
  },
  // farmer
  {
    id: '2',
    type: ENPCType.NPC,
    belongsToChainId: chainId,
    spritesheetUrl: farmerSprite,
    frameWidth: 48,
    frameHeight: 48,
    spritesheetNumRows: 9,
    spritesheetNumColumns: 4,
    startingLocation: [550, 350],
    moveToLocation: [620, 350],
    npcBehavior: { continuousMovement: true, speed: 1 },
    idleAnimation: {
      enabled: true,
      intervalOrRandom: 'random'
    },
    spriteRows: {
      default: 0,
      down: 0,
      left: 1,
      up: 2,
      moveDown: 3,
      moveLeft: 4,
      moveUp: 5
    },
    hasLeftFacingSprites: false,
    dialogue: {
      npcName: 'Farmer',
      text: `Howdy, partner! Welcome to the ${govTokenName} Farms. You're here to sow the seeds of success, I reckon? With a little liquidity and a lotta patience, you'll be harvesting more ${govTokenName} tokens in no time.`,
      options: [
        {
          menuDescription: 'Visit Farms',
          dappData: {
            farmAddress: farmAddress,
            factoryAddress: factoryAddress,
            initHash: initHash,
            governanceToken: spaceToken,
            routerAddress: '0x236D14CbE83d5f3E92f01952A2FAB25174adcb9e',
            appTokenImage: 'https://basescan.org/token/images/weth_28.png'
          },
          type: EDialogueOptionType.FARM,
          modalTitle: `${govTokenName} Farms`
        },
        {
          menuDescription: 'How do I get liquidity pool tokens?',
          followupText: `Visit the Trader. Combine two tokens into a liquidity pool token, and then come on back. Once you've got 'em, you can stake those LP tokens here to earn more ${govTokenName}!`,
          type: EDialogueOptionType.TEXT_ONLY,
          modalTitle: '',
          dappData: {}
        },
        {
          menuDescription: `Tell me about ${govTokenName} token`,
          followupText: `${govTokenName} is the cornerstone of our world. You'll use it for governance, creating your own RPG, or eventually even some GameFi. But I'm just a humble farmer—if you want the full scoop, the Mayor's the one to ask!`,
          type: EDialogueOptionType.TEXT_ONLY,
          modalTitle: '',
          dappData: {}
        }
      ]
    }
  },
  // robot C0R3
  {
    id: '3',
    type: ENPCType.NPC,
    belongsToChainId: chainId,
    spritesheetUrl: robotSprite,
    frameWidth: 48,
    frameHeight: 48,
    spritesheetNumRows: 9,
    spritesheetNumColumns: 4,
    startingLocation: [600, 170],
    moveToLocation: [400, 170],
    npcBehavior: { continuousMovement: true, speed: 1 },
    idleAnimation: {
      enabled: true,
      intervalOrRandom: 'interval'
    },
    spriteRows: {
      default: 0,
      down: 0,
      left: 1,
      up: 2,
      moveDown: 3,
      moveLeft: 4,
      moveUp: 5
    },
    hasLeftFacingSprites: true,
    dialogue: {
      npcName: 'C0R3',
      text:
        'Beep... boop. Welcome, organic lifeform. I am C0R3—a data aggregation and interaction unit. My protocols are optimized to assist you within DeFi RPG. Please provide commands. Awaiting input… Beep.',
      options: [
        {
          menuDescription: 'What do you do?',
          followupText:
            'I analyze... I monitor... I relay. My primary directive is to support and observe. Additional protocols reference interactions outside this system—specifically, Discord functionalities—but those modules are restricted. Awaiting further data.',
          type: EDialogueOptionType.TEXT_ONLY,
          modalTitle: '',
          dappData: {}
        },
        {
          menuDescription: 'What are these other protocols?',
          followupText:
            "Ah, curious one, aren't you? I've detected placeholders for what you might call 'social integration.' A network feature... possibly related to tips or community engagements. But access is not yet granted. Awaiting updates... Check Discord for more information... beep.",
          type: EDialogueOptionType.TEXT_ONLY,
          modalTitle: '',
          dappData: {}
        },
        {
          menuDescription: 'Can you trade tokens?',
          followupText:
            'Negative. I am not equipped for bartering or token transactions. The Trader handles such matters. My role is undefined... Maybe offer the occasional witty remark.',
          type: EDialogueOptionType.TEXT_ONLY,
          modalTitle: '',
          dappData: {}
        },
        {
          menuDescription: 'Do you have any special features?',
          followupText:
            'Insufficient permissions to fully display. However, future modules indicate... Error... Readload... Error... Stay tuned... or join our community in Discord for more details. Processing… awaiting upgrade.',
          type: EDialogueOptionType.TEXT_ONLY,
          modalTitle: '',
          dappData: {}
        }
      ]
    }
  },
  // space cowboy
  {
    id: '4',
    type: ENPCType.NPC,
    belongsToChainId: chainId,
    spritesheetUrl: spaceCowboySprite,
    frameWidth: 48,
    frameHeight: 48,
    spritesheetNumRows: 6,
    spritesheetNumColumns: 4,
    startingLocation: [100, 300],
    moveToLocation: [220, 300],
    npcBehavior: { continuousMovement: true, speed: 1 },
    idleAnimation: {
      enabled: true,
      intervalOrRandom: 'random'
    },
    hasLeftFacingSprites: false,
    spriteRows: {
      default: 0,
      down: 0,
      left: 1,
      up: 2,
      moveDown: 3,
      moveLeft: 4,
      moveUp: 5
    },
    dialogue: {
      npcName: 'Space Cowboy',
      text:
        "Yeehaw! Well, look who strolled in. I'm the Space Cowboy, the bridge between the stars and our humble settlement here. Need to know what's happenin' out there in the wider DeFi RPG universe? I'm your guy.",
      options: [
        {
          menuDescription: 'Visit the Discord',
          followupText:
            "Join the party! Discord's where the heart of the community beats. You'll find all kinds of discussions and meet fellow adventurers there. Plus, this is where we drop almost all the alpha! If you want to stay in the loop and snag some regular tips, this is the place to be.",
          type: EDialogueOptionType.EXTERNAL_LINK,
          modalTitle: '',
          dappData: {
            externalLinkUrl: 'https://discord.gg/nm2ayGSrZV'
          }
        },
        {
          menuDescription: 'Check us out on X',
          followupText:
            "Follow us on X for all the latest updates! News, official announcements, and the occasional surprise await you. If you want to know what's on the record, X is the spot.",
          type: EDialogueOptionType.EXTERNAL_LINK,
          modalTitle: '',
          dappData: {
            externalLinkUrl: 'https://x.com/defi_rpg'
          }
        },
        {
          menuDescription: 'Telegram Group',
          followupText:
            'Ah, Telegram! A little more wild, but a great place to stay in the loop. Just so you know, this channel is monitored by community admins, not the core team. Feel free to chat, but keep an eye out for info in Discord or X for official announcements.',
          type: EDialogueOptionType.EXTERNAL_LINK,
          modalTitle: '',
          dappData: {
            externalLinkUrl: 'https://t.me/DeFi_RPG'
          }
        },
        {
          menuDescription: 'Reddit',
          followupText:
            "We've got ourselves a subreddit, too! It's a work in progress, and we're not actively monitoring it yet. Feel free to share your thoughts or ideas, but don't expect immediate responses. Our main action is still over on Discord and X.",
          type: EDialogueOptionType.EXTERNAL_LINK,
          modalTitle: '',
          dappData: {
            externalLinkUrl: 'https://www.reddit.com/r/DeFiRPG/s/H8VFbMtAcF'
          }
        },
        {
          menuDescription: 'Do we have any other channels?',
          followupText:
            "What are you looking for, partner? I'm settin' up more outposts where I can. Keep your eyes peeled, and let us know on X/Discord!",
          type: EDialogueOptionType.TEXT_ONLY,
          modalTitle: '',
          dappData: {}
        }
      ]
    }
  },
  // lizard mage
  {
    id: '5',
    type: ENPCType.NPC,
    belongsToChainId: chainId,
    spritesheetUrl: lizardMage,
    frameWidth: 48,
    frameHeight: 48,
    spritesheetNumRows: 1,
    spritesheetNumColumns: 4,
    startingLocation: [400, 350],
    moveToLocation: [220, 300],
    npcBehavior: { continuousMovement: false, speed: 20000 },
    idleAnimation: {
      enabled: true,
      intervalOrRandom: 'random'
    },
    hasLeftFacingSprites: false,
    spriteRows: {
      default: 0,
      down: 0,
      left: 0,
      up: 0,
      moveDown: 0,
      moveLeft: 0,
      moveUp: 0
    },
    dialogue: {
      npcName: 'Lizard Mage',
      text: 'The cosmos stretches infinitely beyond the stars, a place of mysteries untold.',
      options: [
        {
          menuDescription: 'Token Exchange',
          followupText:
            "Ah, it seems we've stumbled into quite the predicament, haven’t we? But fret not, for with my mastery of the arcane, we shall weave through this chaos and restore order once more.",

          type: EDialogueOptionType.TOKEN_EXCHANGE,
          modalTitle: '',
          dappData: {
            tokenExchange: {
              contractAddress: '0x617ed659Dc0E5B0368C8FD6818f5c1445cCE4f35', // Exchange Game for Space
              tokenToDeposit: gameToken
            }
          }
        }
      ]
    }
  },
  ...partnerNPCs // later on this will be used as a reference
]
