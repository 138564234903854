import { ChainId } from 'constants/settings'
import { StakingRewardsInfo } from 'hooks/useStakingRewardsInfo'
import { Pair } from '@/lib/sdk/index'
export default function useFilterStakingRewardsInfo(
  chainId: ChainId | undefined,
  active: boolean | undefined = undefined,
  stakingInfo: StakingRewardsInfo[],
  pairToFilterBy?: Pair | null
): StakingRewardsInfo[] {
  const pools = chainId
    ? stakingInfo?.filter(stakingRewardInfo =>
        pairToFilterBy === undefined
          ? true
          : pairToFilterBy === null
          ? false
          : pairToFilterBy.involvesToken(stakingRewardInfo.tokens[0]) &&
            pairToFilterBy.involvesToken(stakingRewardInfo.tokens[1])
      ) ?? []
    : []

  if (active !== undefined) {
    return pools?.filter(stakingRewardInfo => stakingRewardInfo.active === active) ?? []
  }

  return pools
}
