import { DEFAULT_CURRENCY } from 'constants/settings'
import { Currency } from '@/lib/sdk/index'

export default function getBlockchainAdjustedCurrency(
  nativeCurrency: Currency,
  currency: Currency | undefined
): Currency | undefined {
  if (!currency) return currency
  if (currency !== DEFAULT_CURRENCY) return currency
  return nativeCurrency
}
