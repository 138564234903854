import Web3Status from 'components/Web3Status'
import { useActiveWeb3React } from 'hooks'
import React from 'react'
import { FooterContainer, AccountElement } from './Footer.style'

interface FooterLabelProps {
  text?: string
}

// FooterLabel component that receives the text as props
export const Footer: React.FC<FooterLabelProps> = ({ text }) => {
  const { account } = useActiveWeb3React()
  return (
    <FooterContainer>
      {text ?? null}
      <AccountElement active={!!account} style={{ pointerEvents: 'auto' }}>
        <Web3Status />
      </AccountElement>
    </FooterContainer>
  )
}
