import { Interface } from '@ethersproject/abi'
import { TDappData } from 'constants/settings/types/TNpcs'
import { useMemo } from 'react'
import { abi as IUniswapV2PairABI } from '../constants/abis/contracts/IUniswapV2Pair.json'
import { useActiveWeb3React } from '../hooks'
import { useAppSettings } from '../providers/AppSettingsProvider/AppSettingsProvider'
import { useMultipleContractSingleData } from '../state/multicall/hooks'
import { wrappedCurrency } from '../utils/wrappedCurrency'
import { TokenAmount, Pair, Currency, Token } from '@/lib/sdk/index'

const PAIR_INTERFACE = new Interface(IUniswapV2PairABI)

export enum PairState {
  LOADING,
  NOT_EXISTS,
  EXISTS,
  INVALID
}

export function usePairs(
  currencies: [Currency | undefined, Currency | undefined][],
  dappData: TDappData
): [PairState, Pair | null][] {
  const { chainId } = useActiveWeb3React()
  const { settings } = useAppSettings()

  const tokens = useMemo(
    () =>
      currencies.map(([currencyA, currencyB]) => [
        wrappedCurrency(currencyA, chainId, settings.blockchainSettings.currency, settings.wrappedCurrency),
        wrappedCurrency(currencyB, chainId, settings.blockchainSettings.currency, settings.wrappedCurrency)
      ]),
    [chainId, currencies]
  )

  if (!dappData?.factoryAddress) {
    throw new Error('Factory address not found')
  }

  if (!dappData?.initHash) {
    throw new Error('Factory init hash not found')
  }

  const pairAddresses = useMemo(
    () =>
      tokens.map(([tokenA, tokenB]) => {
        return tokenA && tokenB && !tokenA.equals(tokenB) && dappData.initHash && dappData.factoryAddress
          ? Pair.getAddress(dappData.factoryAddress, dappData.initHash, tokenA, tokenB)
          : undefined
      }),
    [tokens]
  )

  const results = useMultipleContractSingleData(pairAddresses, PAIR_INTERFACE, 'getReserves')

  return useMemo(() => {
    return results.map((result, i) => {
      const { result: reserves, loading } = result
      const tokenA = tokens[i][0]
      const tokenB = tokens[i][1]

      if (loading) return [PairState.LOADING, null]
      if (!tokenA || !tokenB || tokenA.equals(tokenB)) return [PairState.INVALID, null]
      if (!reserves) return [PairState.NOT_EXISTS, null]
      const { reserve0, reserve1 } = reserves
      const [token0, token1] = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA]

      if (!dappData.factoryAddress) {
        throw new Error('Factory address not found')
      }

      if (!dappData.initHash) {
        throw new Error('Factory init hash not found')
      }
      if (!dappData.factoryAddress) {
        throw new Error('Factory address not found')
      }

      if (!dappData.initHash) {
        throw new Error('Factory init hash not found')
      }

      return [
        PairState.EXISTS,
        new Pair(
          new TokenAmount(token0, reserve0.toString()),
          new TokenAmount(token1, reserve1.toString()),
          dappData.factoryAddress,
          dappData.initHash
        )
      ]
    })
  }, [results, tokens])
}

export function usePair(dappData: TDappData, tokenA?: Currency, tokenB?: Currency): [PairState, Pair | null] {
  return usePairs([[tokenA, tokenB]], dappData)[0]
}
