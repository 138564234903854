import { TileAnimatedSprite } from 'game/types/TileAnimatedSprite'
import { Application, extras } from 'pixi.js'
import { ZObjectAnimatedSprite } from '../../types/ZObjectAnimatedSprite'

// Function to check for collision using Euclidean distance
function checkCollision(x1: number, y1: number, pos2: { x: number; y: number }, tileSize = 32): boolean {
  const distanceX = x1 - pos2.x
  const distanceY = y1 - pos2.y

  // Calculate the Euclidean distance (hypotenuse)
  const distance = Math.sqrt(distanceX * distanceX + distanceY * distanceY)

  // If the distance is less than or equal to the tile size, a collision has occurred
  return distance < tileSize
}

// Ensure that objects and tiles are loaded in the PIXI stage
function ensureObjectsLoaded(app: Application): boolean {
  const retrievedZoneObjectsContainer = app.stage.getChildByName('zoneObjects')
  const retrievedBackgroundContainer = app.stage.getChildByName('background')

  // Ensure both containers are populated with children before proceeding
  return (
    retrievedZoneObjectsContainer &&
    retrievedZoneObjectsContainer.children.length > 0 &&
    retrievedBackgroundContainer &&
    retrievedBackgroundContainer.children.length > 0
  )
}

// Function to check collision with dead (blocked) tiles
function collisionWithDeadTiles(app: Application, newX: number, newY: number): boolean {
  const retrievedBackgroundContainer = app.stage.getChildByName('background')

  if (retrievedBackgroundContainer) {
    const findAllDeadSpace = retrievedBackgroundContainer.children.filter(
      tile => tile instanceof TileAnimatedSprite && tile.isBlockable
    ) as TileAnimatedSprite[]

    // Check if the player's new position is inside any of the dead space tiles
    return findAllDeadSpace.some(tile => checkCollision(newX, newY, { x: tile.x, y: tile.y }, tile.width))
  }

  return false
}

// Function to check collision with objects (like trees, rocks, etc.)
function collisionWithObjects(app: Application, newX: number, newY: number): boolean {
  const retrievedZoneObjectsContainer = app.stage.getChildByName('zoneObjects')

  if (retrievedZoneObjectsContainer) {
    const findAllObjects = retrievedZoneObjectsContainer.children.filter(
      obj => obj instanceof ZObjectAnimatedSprite && obj.isBlockable
    ) as ZObjectAnimatedSprite[]
    // console.log(findAllObjects.map(i => i.getBounds()))
    // Check if the player's new position is inside any of the object tiles
    return findAllObjects.some(obj => checkCollision(newX, newY, { x: obj.x, y: obj.y }, obj.width))
  }
  return false
}

// Main function to move the NPC with collision detection
export const moveWithCollision = (
  npcSprite: extras.AnimatedSprite,
  moveToLocation: [number, number],
  speed: number,
  otherSpritePositions: { x: number; y: number }[],
  app: Application
) => {
  // Ensure objects are loaded before allowing movement
  if (!ensureObjectsLoaded(app)) {
    return // Skip movement if objects are not yet loaded
  }

  const distanceToMoveX = moveToLocation[0] - npcSprite.x
  const distanceToMoveY = moveToLocation[1] - npcSprite.y

  let newX = npcSprite.x
  let newY = npcSprite.y

  // Calculate new X position based on speed and direction
  if (Math.abs(distanceToMoveX) >= speed) {
    newX += Math.sign(distanceToMoveX) * speed
  }

  // Calculate new Y position based on speed and direction
  if (Math.abs(distanceToMoveY) >= speed) {
    newY += Math.sign(distanceToMoveY) * speed
  }

  // Check collision with other sprites
  const collisionWithOtherSprites = otherSpritePositions.some(npcPos => checkCollision(newX, newY, npcPos))

  // Check collision with dead tiles (blocked tiles)
  const collisionWithTiles = collisionWithDeadTiles(app, newX, newY)

  // Check collision with objects (like trees, rocks, etc.)
  const collisionWithObj = collisionWithObjects(app, newX, newY)

  // Only move if there's no collision with sprites, dead tiles, or objects, and if it's within the boundaries
  if (!collisionWithOtherSprites && !collisionWithTiles && !collisionWithObj) {
    npcSprite.x = newX
    npcSprite.y = newY
  }
}
