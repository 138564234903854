import { TTiles } from 'constants/settings/types/TTiles'
import { TZones } from 'constants/settings/types/TZones'
import { TileAnimatedSprite } from 'game/types/TileAnimatedSprite'
import { Application, Container } from 'pixi.js'

export const addTilesToLoader = (app: Application, tiles: TTiles) => {
  Object.keys(tiles.tileImageMappings).forEach(key => {
    const tile = tiles.tileImageMappings[key]
    app.loader.add(key, tile.imageUrl)
  })
}

export const mapTilesToLoader = (app: Application, tileContainer: Container, zones: TZones, tiles: TTiles) => {
  app.loader.load((_loader, resources) => {
    const tileSize = 32

    for (let i = 0; i < zones.tileLayout.length; i++) {
      const currentArray = zones.tileLayout[i]
      for (let j = 0; j < currentArray.length; j++) {
        if (typeof currentArray[j] === 'number') {
          const tile = resources[currentArray[j]].texture
          const tileMetadata = tiles.tileImageMappings[currentArray[j]]
          // tile.baseTexture.scaleMode = PIXI.SCALE_MODES.NEAREST
          const tileSprite = new TileAnimatedSprite(tile)
          tileSprite.x = j * tileSize
          tileSprite.y = i * tileSize
          tileSprite.height = tileSize
          tileSprite.width = tileSize
          tileSprite.name = `tile-${i}-${tile.textureCacheIds[0]}`
          tileSprite.isBlockable = tileMetadata.blockable
          tileSprite.anchor.set(0.5, 0.5)
          tileContainer.addChild(tileSprite)
        }
      }
    }
  })
}
