import { DEFAULT_CURRENCY, ChainId } from 'constants/settings'
import isEqual from 'lodash.isequal'
import invariant from 'tiny-invariant'
import { Currency } from './currency'
import { Price } from './fractions/price'
import { Pair } from './pair'
import { Token } from './token'

export class Route {
  public readonly pairs: Pair[]
  public readonly path: Token[]
  public readonly input: Currency
  public readonly output: Currency
  public readonly midPrice: Price

  /**
   * Constructs a new Route instance, defining a path between input and output currencies through a series of pairs.
   *
   * @param pairs - An array of trading pairs (`Pair[]`) that form the route. The pairs must all be on the same chain.
   * @param input - The input currency for the route. It must be either a `Token` involved in the first pair or a default base currency.
   * @param nativeCurrency - The native currency of the chain where the pairs exist (e.g., ETH, BNB).
   * @param output - (Optional) The output currency of the route. It must be either a `Token` involved in the last pair or a default base currency.
   *
   * @throws Will throw an error if the pairs array is empty or if the pairs do not all have the same chain ID.
   * @throws Will throw an error if the input currency is not involved in the first pair.
   * @throws Will throw an error if the output currency is not involved in the last pair, if specified.
   * @throws Will throw an error if the path cannot be constructed properly from the given pairs and tokens.
   */
  public constructor(
    pairs: Pair[],
    input: Currency,
    nativeCurrency: Currency,
    wrappedCurrency: Token,
    output?: Currency
  ) {
    invariant(pairs.length > 0, 'PAIRS')
    invariant(
      pairs.every(pair => pair.chainId === pairs[0].chainId),
      'CHAIN_IDS'
    )

    invariant(
      (input instanceof Token && pairs[0].involvesToken(input)) ||
        (input &&
          [nativeCurrency, DEFAULT_CURRENCY].some(item => isEqual(item, input)) &&
          pairs[0].involvesToken(wrappedCurrency)),
      'INPUT'
    )
    invariant(
      typeof output === 'undefined' ||
        (output instanceof Token && pairs[pairs.length - 1].involvesToken(output)) ||
        (output &&
          [nativeCurrency, DEFAULT_CURRENCY].some(item => isEqual(item, output)) &&
          pairs[pairs.length - 1].involvesToken(wrappedCurrency)),
      'OUTPUT'
    )

    const path: Token[] = [input instanceof Token ? input : wrappedCurrency]
    for (const [i, pair] of pairs.entries()) {
      const currentInput = path[i]
      invariant(currentInput.equals(pair.token0) || currentInput.equals(pair.token1), 'PATH')
      const output = currentInput.equals(pair.token0) ? pair.token1 : pair.token0
      path.push(output)
    }

    this.pairs = pairs
    this.path = path
    this.midPrice = Price.fromRoute(this)
    this.input = input
    this.output = output ?? path[path.length - 1]
  }

  public get chainId(): ChainId {
    return this.pairs[0].chainId
  }
}
