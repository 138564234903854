import { useAppConstant } from 'providers/AppSettingsProvider/hooks/useAppConstant'
import React from 'react'
import { ExternalLinks } from '../ExternalLinks/ExternalLinks'
import { MapIcon } from '../Icons/Icons'
import { IconWithModal } from '../IconWithModal/IconWithModal'

export const BlockchainIcon: React.FC = () => {
  const { settings } = useAppConstant()
  if (!settings.platformLinks || settings?.platformLinks.length === 0) {
    return <></>
  }
  return (
    <IconWithModal
      IconComponent={MapIcon}
      triggerKey="b"
      overlayLetter="B"
      modalTitle="Get connected"
      modalContent={<ExternalLinks links={settings.platformLinks} />}
    />
  )
}
