import { NPC } from 'constants/settings/types/TNpcs'
import { NPCAnimatedSprite } from 'game/types/NPCAnimatedSprite'
import { Application, Texture, Container } from 'pixi.js'
import { enableNPCMovement } from './utils/npcMovement'

// Function to add NPC to the game using preloaded resources.
// Returns a cleanup function to remove the NPC
export function addNPC(app: Application, npcData: NPC, container: Container, textures: Texture[][]): () => void {
  if (!textures) {
    console.error(`Error: No textures provided for NPC ${npcData.id}`)
    return () => ({})
  }

  // Map NPC directions to corresponding rows in the sprite sheet
  const directionMapping: { [key: string]: number } = {
    down: npcData.spriteRows?.down ?? 0,
    left: npcData.spriteRows?.left ?? 1,
    up: npcData.spriteRows?.up ?? 2,
    moveDown: npcData.spriteRows?.moveDown ?? 3,
    moveLeft: npcData.spriteRows?.moveLeft ?? 4,
    moveUp: npcData.spriteRows?.moveUp ?? 5
  }

  // Default to 'down' idle if no matching direction is found
  const initialRow = directionMapping[npcData.spriteRows?.default ?? 0] || 0
  const npcSprite = new NPCAnimatedSprite(textures[initialRow])

  npcSprite.anchor.set(0.5, 0.5)
  npcSprite.width = 96
  npcSprite.height = 96

  npcSprite.animationSpeed = 0.1

  npcSprite.x = npcData.startingLocation[0] || app.renderer.width / 2
  npcSprite.y = npcData.startingLocation[1] || app.renderer.height / 2

  npcSprite.play()

  npcSprite.NPCData = npcData
  container.addChild(npcSprite)

  // Handle NPC movement
  if (npcData.npcBehavior.continuousMovement) {
    enableNPCMovement(npcSprite, npcData.moveToLocation, npcData, textures, app)
  }

  // Handle NPC idle animation
  let idleInterval: NodeJS.Timeout | null = null

  if (npcData.idleAnimation.enabled) {
    idleInterval = setInterval(
      () => {
        if (!npcData.npcBehavior.continuousMovement) {
          npcSprite.textures = textures[initialRow]
          npcSprite.play()
        }
      },
      npcData.idleAnimation.intervalOrRandom === 'interval' ? 2000 : Math.random() * 5000
    )
  }

  // Cleanup function to remove event listeners, ticker callback, intervals, and destroy the sprite
  return () => {
    console.log('Cleaning up NPC:', npcData.id)
    if (idleInterval) clearInterval(idleInterval)
    container.removeChild(npcSprite)
    npcSprite.destroy()
    console.log('Finished cleaning up NPC')
  }
}
