import { ChainId } from 'constants/settings'
import { Result } from 'state/multicall/hooks'
import calculateTotalStakedAmount from './calculateTotalStakedAmount'
import getPair from './getPair'
import { wrappedCurrency } from './wrappedCurrency'
import { Token, TokenAmount, Fraction, Currency } from '@/lib/sdk/index'
import { JSBI } from '@/lib/sdk/index'

/** TODO this might be something we can simplify in our settings file.
 *    what i would like to see is to be able to tell the platform what price i want our tokens to quote against.
 *    For instance if BASE we are doing DAI (18 decimal) and CRONOS we do USDC(6decimal) then
 *    i would expect the aprs to the farm and the price of a token to show as expected */
function pairCurrencyAmountInWeth(
  baseToken: Token | undefined,
  tokens: Record<string, any>,
  valueOfTotalStakedAmountInPairCurrency: TokenAmount
): TokenAmount | Fraction | undefined {
  if (!baseToken) return valueOfTotalStakedAmountInPairCurrency

  const calculatePrice = (tokenPrice: any) => {
    return tokenPrice
      ? valueOfTotalStakedAmountInPairCurrency.multiply(tokenPrice)
      : valueOfTotalStakedAmountInPairCurrency
  }

  const calculateScaledPrice = (tokenPrice: any) => {
    return tokenPrice
      ? valueOfTotalStakedAmountInPairCurrency.multiply(tokenPrice).multiply(JSBI.BigInt(2))
      : valueOfTotalStakedAmountInPairCurrency
  }

  // Main switch statement to handle various tokens
  switch (baseToken.symbol?.toUpperCase()) {
    case tokens?.WETH?.token?.symbol?.toUpperCase():
      return valueOfTotalStakedAmountInPairCurrency

    case tokens?.govToken?.token?.symbol?.toUpperCase():
      return calculatePrice(tokens?.govToken?.price)

    case tokens?.STABLE?.token?.symbol?.toUpperCase():
      return calculateScaledPrice(tokens?.STABLE?.price)

    case tokens?.USDC?.token?.symbol?.toUpperCase():
      return calculateScaledPrice(tokens?.govToken?.price)

    case tokens?.BUSD?.token?.symbol?.toUpperCase():
      return calculatePrice(tokens?.BUSD?.price)

    case tokens?.bscBUSD?.token?.symbol?.toUpperCase():
      return calculatePrice(tokens?.bscBUSD?.price)

    case tokens?.bridgedETH?.token?.symbol?.toUpperCase():
      return calculatePrice(tokens?.bridgedETH?.price)

    case tokens?.bscBNB?.token?.symbol?.toUpperCase():
      return calculatePrice(tokens?.bscBNB?.price)

    default:
      return valueOfTotalStakedAmountInPairCurrency
  }
}

export default function calculateWethAdjustedTotalStakedAmount(
  chainId: ChainId,
  baseToken: Token | undefined,
  tokenData: Record<string, any>,
  tokens: [Token, Token],
  totalLpTokenSupply: TokenAmount,
  totalStakedAmount: TokenAmount,
  lpTokenReserves: Result | undefined,
  factoryAddr: string,
  initHash: string,
  nativeCurrency: Currency,
  wrappedCurrencyToken: Token
): TokenAmount | Fraction | undefined {
  if (!baseToken || !lpTokenReserves || !totalLpTokenSupply) return undefined

  const reserve0 = lpTokenReserves?.reserve0
  const reserve1 = lpTokenReserves?.reserve1

  const stakingTokenPair = getPair(
    wrappedCurrency(tokens[0], chainId, nativeCurrency, wrappedCurrencyToken),
    wrappedCurrency(tokens[1], chainId, nativeCurrency, wrappedCurrencyToken),
    reserve0,
    reserve1,
    factoryAddr,
    initHash
  )
  if (!stakingTokenPair) return undefined

  const valueOfTotalStakedAmountInPairCurrency = calculateTotalStakedAmount(
    baseToken,
    stakingTokenPair,
    totalStakedAmount,
    totalLpTokenSupply
  )
  if (!valueOfTotalStakedAmountInPairCurrency) return undefined

  return pairCurrencyAmountInWeth(baseToken, tokenData, valueOfTotalStakedAmountInPairCurrency)
}
