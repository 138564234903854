import { Contract } from '@ethersproject/contracts'
import { abi as GOVERNANCE_ABI } from '@uniswap/governance/build/GovernorAlpha.json'
import { abi as UNI_ABI } from '@uniswap/governance/build/Uni.json'
import { TOKEN_EXCHANGE_ABI } from 'constants/abis/tokenExchange'
import { ChainId } from 'constants/settings'
import { TDappData } from 'constants/settings/types/TNpcs'
import { useAppSettings } from 'providers/AppSettingsProvider/AppSettingsProvider'
import { useMemo } from 'react'
import { useActiveWeb3React } from './index'
import {
  ARGENT_WALLET_DETECTOR_ABI,
  ARGENT_WALLET_DETECTOR_MAINNET_ADDRESS
} from '../constants/abis/argent-wallet-detector'
import ENS_PUBLIC_RESOLVER_ABI from '../constants/abis/contracts/ens-public-resolver.json'
import ENS_ABI from '../constants/abis/contracts/ens-registrar.json'
import ERC20_ABI from '../constants/abis/contracts/erc20.json'
import { abi as GOVERNANCE_TOKEN_ABI } from '../constants/abis/contracts/governance-token.json'
import { abi as IUniswapV2PairABI } from '../constants/abis/contracts/IUniswapV2Pair.json'
import { abi as MASTER_FARM_ABI } from '../constants/abis/contracts/master-breeder.json'
import { abi as MERKLE_DISTRIBUTOR_ABI } from '../constants/abis/contracts/merkle-distributor.json'
import { abi as MASTER_VAULTS_ABI } from '../constants/abis/contracts/pit-breeder.json'
import { abi as VAULT_TOKEN_ABI } from '../constants/abis/contracts/pit.json'
import { abi as STAKING_REWARDS_ABI } from '../constants/abis/contracts/staking-rewards.json'
import WETH_ABI from '../constants/abis/contracts/weth.json'
import { ERC20_BYTES32_ABI } from '../constants/abis/erc20'
import { MIGRATOR_ABI, MIGRATOR_ADDRESS } from '../constants/abis/migrator'
import { MULTICALL_ABI } from '../constants/multicall'
import { V1_EXCHANGE_ABI, V1_FACTORY_ABI, V1_FACTORY_ADDRESSES } from '../constants/v1'
import { getContract } from '../utils'

// returns null on errors
function useContract(address: string | undefined, ABI: any, withSignerIfPossible = true): Contract | null {
  const { library, account } = useActiveWeb3React()

  return useMemo(() => {
    if (!address || !ABI || !library) return null
    try {
      return getContract(address, ABI, library, withSignerIfPossible && account ? account : undefined)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [address, ABI, library, withSignerIfPossible, account])
}

export function useV1FactoryContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId && V1_FACTORY_ADDRESSES[chainId], V1_FACTORY_ABI, false)
}

export function useV2MigratorContract(): Contract | null {
  return useContract(MIGRATOR_ADDRESS, MIGRATOR_ABI, true)
}

export function useV1ExchangeContract(address?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(address, V1_EXCHANGE_ABI, withSignerIfPossible)
}

export function useTokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_ABI, withSignerIfPossible)
}

export function useWETHContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  const { settings } = useAppSettings()
  return useContract(chainId ? settings.wrappedCurrency.address : undefined, WETH_ABI, withSignerIfPossible)
}

export function useArgentWalletDetectorContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  const { settings } = useAppSettings()
  return useContract(
    chainId === settings.blockchainSettings.chainId ? ARGENT_WALLET_DETECTOR_MAINNET_ADDRESS : undefined,
    ARGENT_WALLET_DETECTOR_ABI,
    false
  )
}

export function useENSRegistrarContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  let address: string | undefined
  if (chainId) {
    switch (chainId) {
      case ChainId.MAINNET:
      case ChainId.GÖRLI:
      case ChainId.ROPSTEN:
      case ChainId.RINKEBY:
        address = '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e'
        break
    }
  }
  return useContract(address, ENS_ABI, withSignerIfPossible)
}

export function useENSResolverContract(address: string | undefined, withSignerIfPossible?: boolean): Contract | null {
  return useContract(address, ENS_PUBLIC_RESOLVER_ABI, withSignerIfPossible)
}

export function useBytes32TokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_BYTES32_ABI, withSignerIfPossible)
}

export function usePairContract(pairAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(pairAddress, IUniswapV2PairABI, withSignerIfPossible)
}

export function useMulticallContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  const { settings } = useAppSettings()
  return useContract(chainId && settings.multiCallAddr, MULTICALL_ABI, false)
}

export function useMerkleDistributorContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  const { settings } = useAppSettings()
  return useContract(chainId ? settings.merkleDistributorAddr : undefined, MERKLE_DISTRIBUTOR_ABI, true)
}

export function useGovernanceContract(dappData: TDappData): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(
    chainId && dappData.governanceToken?.address ? dappData.governanceToken?.address : undefined,
    GOVERNANCE_ABI,
    true
  )
}

export function useUniContract(dappData: TDappData): Contract | null {
  return useContract(dappData.governanceToken?.address, UNI_ABI, true)
}

export function useGovTokenContract(dappData: TDappData): Contract | null {
  return useContract(dappData.governanceToken?.address, GOVERNANCE_TOKEN_ABI, true)
}

export function useVaultTokensContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  const { settings } = useAppSettings()
  return useContract(chainId ? settings.vaultToken.address : undefined, VAULT_TOKEN_ABI, withSignerIfPossible)
}

export function useMasterVaultContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  const { settings } = useAppSettings()
  return useContract(chainId ? settings?.masterVault?.address : undefined, MASTER_VAULTS_ABI, withSignerIfPossible)
}

export function useStakingContract(stakingAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(stakingAddress, STAKING_REWARDS_ABI, withSignerIfPossible)
}

export function useMasterFarmContract(dappData: TDappData, withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  const address = chainId && dappData?.farmAddress ? dappData.farmAddress : undefined
  return useContract(address, MASTER_FARM_ABI, withSignerIfPossible)
}

export function useTokenExchangeContract(dappData: TDappData, withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  const address =
    chainId && dappData?.tokenExchange?.contractAddress ? dappData.tokenExchange.contractAddress : undefined
  return useContract(address, TOKEN_EXCHANGE_ABI, withSignerIfPossible)
}
