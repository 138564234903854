import TokenExchangeModal from 'components/TokenExchange/TokenExchangeModal'
import Typewriter from 'components/Typewriter/Typewriter'
import { EDialogueOptionType, TDappData, TDialogueOption, TDialogue } from 'constants/settings/types/TNpcs'
import Farms from 'pages/Farms'
import Pool from 'pages/Pool'
import PoolFinder from 'pages/PoolFinder'
import Swap from 'pages/Swap'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import DialogueModal from './DialogueModal'

interface DialogueProps {
  show: boolean
  npcName?: string
  dialogue?: TDialogue
  onClose: () => void
}

const StyledButton = styled.button<{ variant?: string }>`
  background: ${({ variant, theme }) => (variant === 'secondary' ? theme.secondary2 : theme.secondary1)};
  color: ${({ theme }) => theme.text2};
  border: none;
  border-radius: 4px;
  padding: 15px 20px;
  margin: 10px 0;
  cursor: pointer;
  font-size: 1em;
  width: 100%;
  font-weight: bold;

  &:hover {
    color: ${({ variant, theme }) => (variant === 'secondary' ? theme.primary2 : theme.primary2)};
    &::before {
      content: '>';
      margin-right: 5px;
    }
  }

  &:focus {
    color: ${({ variant, theme }) => (variant === 'secondary' ? theme.primary2 : theme.primary2)};
    &::before {
      content: '>';
      margin-right: 5px;
    }
  }
`
const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const SkipText = styled.span`
  cursor: pointer; /* Indicates that it's clickable */
  font-weight: bold; /* Makes the text bold */
  color: ${({ theme }) => theme.text1};
  text-decoration: underline; /* Optional hover effect */
  margin-right: 10px;

  &:hover {
    text-decoration: underline;
  }
`

const DialogueContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 15px;
  color: ${({ theme }) => theme.bg1};
`

const NPCDialogueModal: React.FC<DialogueProps> = ({ show, npcName, dialogue, onClose }) => {
  const options = dialogue?.options ?? []
  const dialogueText = dialogue?.text ?? ''
  const [selectedOption, setSelectedOption] = useState<TDialogueOption | null>(null)
  const [currentDialogue, setCurrentDialogue] = useState<string>(dialogueText)
  const [isTypingComplete, setTypingComplete] = useState(false)
  const [showComponentAfterFollowUp, setShowComponentAfterFollowUp] = useState(false) // For showing the component after follow-up text
  const [isContinueVisible, setIsContinueVisible] = useState(false) // To control visibility of the Continue button

  // Update current dialogue when the NPC dialogue prop changes
  useEffect(() => {
    setCurrentDialogue(dialogueText || '')
    setIsContinueVisible(false) // Reset "Continue" button when dialogue changes
  }, [dialogueText])

  const handleTypewriterComplete = () => {
    setTypingComplete(true)
    if (selectedOption && selectedOption.followupText) {
      setIsContinueVisible(true) // Show the "Continue" button after typing completes
    }
  }

  const handleOptionSelect = (option: TDialogueOption) => {
    setSelectedOption(option)

    if (option.followupText) {
      // Set the follow-up text without clearing the dialogue
      setCurrentDialogue(option.followupText)
      setTypingComplete(false) // Reset typing state
      setIsContinueVisible(false) // Hide the "Continue" button until typing completes
    } else {
      // If no follow-up text, directly show the component after typing completes
      setTypingComplete(true)
      setShowComponentAfterFollowUp(true)
    }
  }

  const handleContinue = () => {
    setShowComponentAfterFollowUp(true) // Show the next component
    setIsContinueVisible(false) // Hide the "Continue" button
    if (selectedOption && Object.keys(selectedOption.dappData).length === 0) {
      onClose() // if its only text to close the dialogue
    }
  }

  const skipTyping = () => {
    setTypingComplete(true)
    if (selectedOption && selectedOption.followupText) {
      setIsContinueVisible(true)
    }
  }

  const renderOptionComponent = (dappData: TDappData) => {
    switch (selectedOption?.type) {
      case EDialogueOptionType.FARM:
        return <Farms dappData={dappData} />
      case EDialogueOptionType.SWAP:
        return <Swap dappData={dappData} />
      case EDialogueOptionType.POOLS:
        return <Pool dappData={dappData} />
      case EDialogueOptionType.IMPORT_POOLS:
        return <PoolFinder dappData={dappData} />
      case EDialogueOptionType.EXTERNAL_LINK:
        window.open(dappData?.externalLinkUrl ?? '', '_blank')
        setSelectedOption(null)
        return <></>
      case EDialogueOptionType.TOKEN_EXCHANGE:
        return <TokenExchangeModal dappData={dappData} />
      default:
        return null
    }
  }

  return (
    <DialogueModal isOpen={show} onClose={onClose} title={npcName}>
      {/* Show the dialogue with the typewriter effect */}
      <DialogueContentContainer>
        <Typewriter
          text={currentDialogue}
          onComplete={handleTypewriterComplete}
          speed={dialogue?.speedTextMS ?? undefined}
        />
        {!isTypingComplete && (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <SkipText onClick={skipTyping}>{`Press Space to skip`}</SkipText>
          </div>
        )}
      </DialogueContentContainer>

      {/* Show options only when typing is complete and no option is selected */}
      {options && !selectedOption && isTypingComplete && (
        <OptionsContainer>
          {options.map((option, index) => (
            <StyledButton key={index} onClick={() => handleOptionSelect(option)}>
              {option.menuDescription}
            </StyledButton>
          ))}
        </OptionsContainer>
      )}

      {/* Show "Continue" button after follow-up text typing is complete */}
      {isTypingComplete && isContinueVisible && <StyledButton onClick={handleContinue}>Continue</StyledButton>}

      {/* Render follow-up component after follow-up text typing is complete */}
      {isTypingComplete && showComponentAfterFollowUp && selectedOption && (
        <div>{renderOptionComponent(selectedOption.dappData)}</div>
      )}
    </DialogueModal>
  )
}

export default NPCDialogueModal
