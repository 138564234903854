import { TTiles } from 'constants/settings/types/TTiles'
import { Application, Texture, extras, Container } from 'pixi.js'

export const isTileBlockable = (tileId: number, tiles: TTiles): boolean => {
  return tiles.tileImageMappings[tileId].blockable ?? false
}

export function findNonBlockingTile(tileLayout: number[][], tiles: TTiles): { x: number; y: number } | null {
  for (let row = 0; row < tileLayout.length; row++) {
    for (let col = 0; col < tileLayout[row].length; col++) {
      const tileId = tileLayout[row][col]
      if (!isTileBlockable(tileId, tiles)) {
        return { x: 10, y: 2 } // todo this only accounts for tiles not for objects .. manually adding safe points for now need to fix later
      }
    }
  }
  return null // No non-blocking tile found
}

// Function to initialize the player's animated sprite
export function initializePlayerSprite(
  _app: Application,
  textures: Texture[][],
  playerContainer: Container,
  startTile: { x: number; y: number }
): extras.AnimatedSprite {
  const animatedSprite = new extras.AnimatedSprite(textures[0])
  animatedSprite.anchor.set(0.5, 0.5)
  animatedSprite.width = 98
  animatedSprite.height = 98
  animatedSprite.x = startTile.x * 40
  animatedSprite.y = startTile.y * 340
  animatedSprite.animationSpeed = 0.1
  animatedSprite.play()

  playerContainer.addChild(animatedSprite)
  return animatedSprite
}
