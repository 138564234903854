import { Game } from 'game/game'
import { useAppSettings } from 'providers/AppSettingsProvider/AppSettingsProvider'
import React, { Suspense, useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import styled, { createGlobalStyle } from 'styled-components'
import Web3ReactManager from '../components/Web3ReactManager'
import DarkModeQueryParamReader from '../theme/DarkModeQueryParamReader'

const GlobalStyle = createGlobalStyle`
  /* Global scrollbar styling */
  ::-webkit-scrollbar {
    width: 2px; 
  }

  ::-webkit-scrollbar-track {
    background: #1a1a1a; 
  }

  ::-webkit-scrollbar-thumb {
    background: #333;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #444;
  }

  /* Firefox scrollbar customization */
  body {
    scrollbar-width: thin;
    scrollbar-color: #333 #1a1a1a; /* Thumb and track colors */
  }
`

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  overflow-x: hidden;
`

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 10;
  z-index: 1;
`

export default function GameApp() {
  const { settings } = useAppSettings()
  const platformName = settings.platformName

  useEffect(() => {
    document.title = platformName
  }, [platformName])

  return (
    <>
      <GlobalStyle />
      <Suspense fallback={null}>
        <Route component={DarkModeQueryParamReader} />
        <AppWrapper>
          <BodyWrapper>
            <Web3ReactManager>
              <Switch>
                <Route exact path="/" component={Game} />
              </Switch>
            </Web3ReactManager>
          </BodyWrapper>
        </AppWrapper>
      </Suspense>
    </>
  )
}
