import React from 'react'
import styled from 'styled-components'
import iconSprite from './icon.png'

// Create a styled component for the sprite
const Sprite = styled.div<{ x: number; y: number; size: number; scale: number }>`
  background-image: url(${iconSprite});
  background-repeat: no-repeat;
  width: 48px;
  height: 48px;
  background-position: ${props => `${props.x}px ${props.y}px`};
  background-size: 840px 840px;
`

export const GearIcon = () => <Sprite x={-370} y={-54} size={48} scale={3} />
export const MapIcon = () => <Sprite x={-58} y={-316} size={32} scale={2.5} />
export const TicketIcon = () => <Sprite x={-56} y={-370} size={32} scale={2.5} />
