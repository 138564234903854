import { ChainId } from 'constants/settings'
import { Token } from '@/lib/sdk/index'

// import getPairTokensWithDefaults from '../utils/getPairTokensWithDefaults'

export const VAULT_TOKEN_POOLS: {
  [chainId in ChainId]?: {
    pid: number
    tokens: [Token, Token]
  }[]
} = {
  [ChainId.HARMONY_MAINNET]: [],
  [ChainId.HARMONY_TESTNET]: [],
  [ChainId.BSC_TESTNET]: [
    // {
    //   pid: 0,
    //   tokens: getPairTokensWithDefaults(ChainId.BSC_TESTNET, 'WBNB/BUSD')
    // }
  ],
  [ChainId.AMOY_TESTNET]: [],
  [ChainId.BUILD_BEAR]: [],
  [ChainId.POLYGON_MAINNET]: [],
  [ChainId.MUMBAI_TESTNET]: []
}
