import NPCDialogueModal from 'components/Dialogue'
import { NPC } from 'constants/settings/types/TNpcs'
import { Application, Container } from 'pixi.js'
import { useAppConstant } from 'providers/AppSettingsProvider/hooks/useAppConstant'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Footer } from './component/Footer/Footer'
import { Menu } from './component/Menu/Menu'
import { MobileControl } from './component/MobileControl/MobileControl'
import WarningBanner from './component/WarningBanner/WarningBanner'
import { loadNpcCharacters, preloadNPCTextures } from './npcCharacters/loadNpcCharacters'
import { loadPlayerCharacter } from './playerCharacter/loadPlayerCharacter'
import { findNonBlockingTile } from './playerCharacter/utils/initializePlayerSprite'
import { addTilesToLoader, mapTilesToLoader } from './tileLoader/tileLoader'
import { cleanup } from './utils/cleanup'
import { drawBasedOnSettings } from './utils/drawBackgroundSettingsTheme'
import { loadObjectsToGame, preloadObjectTextures } from './zoneObjectsLoader/zoneObjectsLoader'

export const Game: React.FC = () => {
  const { player, npcs, chainId, tiles, zones, zoneObjects, settings } = useAppConstant()
  const appRef = useRef<Application | null>(null)
  const [loading, setLoading] = useState(true)
  const [width, setWidth] = useState(window.innerWidth)
  const [height, setHeight] = useState(window.innerHeight)
  const [showDialogue, setShowDialogue] = useState(false)
  const [dialogueData, setDialogueData] = useState<NPC | null>(null)
  const [mobileKeys, setMobileKeys] = useState<{ [key: string]: boolean }>({
    KeyW: false,
    KeyA: false,
    KeyS: false,
    KeyD: false
  })

  useEffect(() => {
    console.log('Starting game with chainId:', chainId)
    setLoading(true)

    // Cleanup previous PixiJS instance if it exists
    if (appRef.current) {
      console.log('Destroying previous PixiJS instance')
      cleanup(appRef.current)
    }

    const rebuildApp = () => {
      console.log('Rebuilding PixiJS application')

      // Create new PixiJS application
      const app = new Application({ autoResize: true, backgroundColor: 0x000000 })
      appRef.current = app // Store reference to the app
      app.renderer.resize(width, height)

      document.getElementById('pixi-container')?.appendChild(app.view)

      // Create containers for layers
      const backgroundThemeContainer = new Container()
      backgroundThemeContainer.name = 'theme'

      const backgroundContainer = new Container()
      backgroundContainer.name = 'background'

      const zObjectContainer = new Container()
      zObjectContainer.name = 'zoneObjects'

      const playerContainer = new Container()
      playerContainer.name = 'player'

      const npcContainer = new Container()
      npcContainer.name = 'npcs'

      // Add the containers to the app
      appRef.current.stage.addChild(backgroundThemeContainer)
      appRef.current.stage.addChild(backgroundContainer)
      appRef.current.stage.addChild(zObjectContainer)
      appRef.current.stage.addChild(npcContainer)
      appRef.current.stage.addChild(playerContainer)

      // PREREQ - Set up initial camera zoom and focus settings
      const cameraZoom = 2 // Zoom factor (adjust as needed)
      appRef.current.stage.scale.set(cameraZoom, cameraZoom) // Apply zoom to the entire stage

      // PREREQ - draw stars in the new starContainer across the full screen
      drawBasedOnSettings(app, backgroundThemeContainer, settings)

      // Step 1 - Load the background
      addTilesToLoader(appRef.current, tiles)
      mapTilesToLoader(appRef.current, backgroundContainer, zones, tiles)

      // Step 2: Preload and load objects into zObjectContainer
      const objectTextures = preloadObjectTextures(zoneObjects) // Preload object textures
      loadObjectsToGame(appRef.current, zObjectContainer, objectTextures, zones, zoneObjects) // Load objects into the container

      // Step 3 - Load the npcs
      const npcTextures = preloadNPCTextures(npcs)
      const currentNpcs = loadNpcCharacters(appRef.current, npcs, npcContainer, npcTextures)

      // Step 4 - Load the character
      const startTile = findNonBlockingTile(zones.tileLayout, tiles) || { x: 0, y: 0 } // Fallback to (0,0) if no valid tile is found
      const currentPlayer = loadPlayerCharacter(
        appRef.current,
        player,
        playerContainer,
        setShowDialogue,
        setDialogueData,
        startTile,
        mobileKeys
      )

      setLoading(false)
      return {
        currentPlayer,
        currentNpcs,
        containers: [backgroundContainer, zObjectContainer, playerContainer, npcContainer, backgroundThemeContainer]
      }
    }

    window.addEventListener('resize', function() {
      if (appRef.current) {
        setWidth(window.innerWidth)
        setHeight(window.innerHeight)
        appRef.current.renderer.resize(window.innerWidth, window.innerHeight)
      }
    })

    // running application
    const { currentPlayer, currentNpcs, containers } = rebuildApp()

    // clean up
    return () => {
      if (appRef.current) {
        currentPlayer && currentPlayer()
        currentNpcs && currentNpcs.forEach(cleanup => cleanup())
        cleanup(appRef.current, containers)
        appRef.current = null
      }
    }
  }, [chainId])

  const handleOnClose = useCallback(() => {
    setDialogueData(null)
    setShowDialogue(false)
  }, [])

  return (
    <>
      <Menu />
      {/* Loading screen */}
      {loading && <div className="loading-screen">Loading new environment...</div>}
      {dialogueData && (
        <NPCDialogueModal
          show={showDialogue}
          npcName={dialogueData?.dialogue?.npcName}
          dialogue={dialogueData?.dialogue}
          onClose={handleOnClose}
        />
      )}

      <MobileControl keys={mobileKeys} />
      {/* PixiJS container is hidden while loading */}
      <div id="pixi-container" style={{ display: loading ? 'none' : 'block' }}></div>
      <Footer />
      <WarningBanner showWarning={true} />
    </>
  )
}
