import { ChainId } from 'constants/settings'
import { Token } from '@/lib/sdk/index'
import { ChainTokenList } from '@/lib/sdk-extra/chainTokenList'

// todo need to think about refactoring this .. dont like its getting static token objects from sdk-extra/index TOKENS
export default function getTokenWithDefault(
  chainId: ChainId | undefined,
  symbol: string,
  wrappedCurrency: Token,
  chainTokenList: ChainTokenList
): Token | undefined {
  if (chainId === undefined) return undefined
  symbol = symbol.toUpperCase()

  switch (symbol) {
    case 'WETH':
    case 'WBNB':
    case 'WONE':
      return wrappedCurrency
    default:
      return chainTokenList.firstBySymbol(symbol)
  }
}
