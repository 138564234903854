import Alert from 'components/Alert/Alert'
import { useAlert } from 'components/Alert/hook/useAlert'
import { SupportedNetwork, SUPPORTED_NETWORKS } from 'constants/settings'
import { useAppConstant } from 'providers/AppSettingsProvider/hooks/useAppConstant'
import React, { useState, useEffect } from 'react'
import { DropdownWrapper, DropdownButton, DropdownContent, DropdownItem, ConnectedLabel } from './SwitchNetwork.style'
import { switchNetwork } from './utils/utils'

export const SwitchNetwork: React.FC = () => {
  const { settings } = useAppConstant()
  const { alert, setAlert, clearAlert } = useAlert()
  const [isOpen, setIsOpen] = useState(false)
  const [selectedNetwork, setSelectedNetwork] = useState<SupportedNetwork | null>(null)
  const [defaultSelectedNetworkName, setDefaultSelectedNetworkName] = useState<string>('Select Network')
  const handleToggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  const handleSwitchNetwork = async (network: SupportedNetwork) => {
    try {
      // Only update the selected network if the switch is successful
      const { chainId, name, rpcUrl, explorerUrl, nativeCurrency } = network
      await switchNetwork(chainId, name, rpcUrl, explorerUrl, nativeCurrency)

      // If successful, set the new selected network
      setSelectedNetwork(network)
    } catch (error) {
      // If there was an error (e.g., user canceled), keep the previous network
      console.error('Failed to switch network:', error)
      setAlert('Network switched failed or was canceled.', false)
    } finally {
      // Close the dropdown no matter what
      setIsOpen(false)
    }
  }

  useEffect(() => {
    if (settings.blockchainSettings.name) {
      setDefaultSelectedNetworkName(settings.blockchainSettings.name)
    }
  }, [settings])

  return (
    <>
      {alert && (
        <Alert
          message={alert.message}
          success={alert.success}
          link={alert.link}
          linkText={alert.linkText}
          close={clearAlert}
          autoCloseAfterMs={5000}
        />
      )}
      <DropdownWrapper>
        <DropdownButton onClick={handleToggleDropdown}>
          {selectedNetwork ? selectedNetwork.name : defaultSelectedNetworkName}
        </DropdownButton>
        <DropdownContent isOpen={isOpen}>
          {SUPPORTED_NETWORKS.map((network: SupportedNetwork) => (
            <DropdownItem
              key={network.chainId}
              onClick={() => handleSwitchNetwork(network)}
              isSelected={
                selectedNetwork?.chainId === network.chainId ||
                settings.blockchainSettings.hexChainId === network.chainId
              }
            >
              {network.name}
              {selectedNetwork?.chainId === network.chainId ||
              settings.blockchainSettings.hexChainId === network.chainId ? (
                <ConnectedLabel>Connected</ConnectedLabel>
              ) : null}
            </DropdownItem>
          ))}
        </DropdownContent>
      </DropdownWrapper>
    </>
  )
}
