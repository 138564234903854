import { npcs as BASE_NPCS } from 'constants/settings/base/npcs'
import { objects as BASE_OBJECTS } from 'constants/settings/base/objects'
import { player as BASE_PLAYER } from 'constants/settings/base/player'
import { BASE } from 'constants/settings/base/settings'
import { BASE_THEME } from 'constants/settings/base/theme'
import { tiles as BASE_TILES } from 'constants/settings/base/tiles'
import { zones as BASE_ZONE } from 'constants/settings/base/zones'
// import { CRONOS } from 'constants/settings/cronos/settings'
// import { CRONOS_THEME } from 'constants/settings/cronos/theme'
import { NPC } from 'constants/settings/types/TNpcs'
import { TObjects } from 'constants/settings/types/TObjects'
import { TSettings } from 'constants/settings/types/TSettings'
import { TThemes } from 'constants/settings/types/TThemes'
import { TTiles } from 'constants/settings/types/TTiles'
import { TZones } from 'constants/settings/types/TZones'
export class AppSettingsClient {
  // TODO: Fix having a default value
  private settings: TSettings = BASE
  private theme: TThemes = BASE_THEME
  private npcs: NPC[] = BASE_NPCS
  private tiles: TTiles = BASE_TILES
  private player: any = BASE_PLAYER // todo need to defined type for player object
  private zones: TZones = BASE_ZONE
  private zoneObjects: TObjects = BASE_OBJECTS

  constructor(chainId: number, onChain: boolean) {
    if (onChain) {
      // get off of the chain
    } else {
      this.loadLocalChainSettings(chainId)
    }
  }

  private loadLocalChainSettings = (chain: number) => {
    switch (chain) {
      case 8453:
        this.settings = BASE
        this.theme = BASE_THEME
        this.npcs = BASE_NPCS
        this.tiles = BASE_TILES
        this.player = BASE_PLAYER
        this.zones = BASE_ZONE
        this.zoneObjects = BASE_OBJECTS
        break
      // case 25:
      //   this.settings = CRONOS
      //   this.theme = CRONOS_THEME
      //   this.npcs = []
      //   this.tiles = {
      //     tileImageMappings: {}
      //   }
      //   this.player = ''
      //   this.zones = {
      //     tileLayout: [],
      //     objects: []
      //   } as TZones
      //   this.zoneObjects = {
      //     objectMapping: {}
      //   }
      //   break
      default:
        console.info('Passed an unrecognized chain')
    }
  }

  public getSettings = () => {
    return {
      settings: this.settings,
      theme: this.theme,
      npcs: this.npcs,
      tiles: this.tiles,
      player: this.player,
      zones: this.zones,
      zoneObjects: this.zoneObjects
    }
  }
}
