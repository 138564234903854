import { TokenList } from '@uniswap/token-lists'
import schema from '@uniswap/token-lists/src/tokenlist.schema.json'
import Ajv from 'ajv'
import contenthashToUri from './contenthashToUri'
import { parseENSAddress } from './parseENSAddress'
import uriToHttp from './uriToHttp'

const tokenListValidator = new Ajv({ allErrors: true }).compile(schema)

/**
 * Contains the logic for resolving a list URL to a validated token list
 * @param listUrl list url
 * @param resolveENSContentHash resolves an ens name to a contenthash
 */
export default async function getTokenList(
  listUrl: string,
  resolveENSContentHash: (ensName: string) => Promise<string>
): Promise<TokenList> {
  // Parse the ENS address if applicable
  const parsedENS = parseENSAddress(listUrl)
  let urls: string[]

  if (parsedENS) {
    let contentHashUri
    try {
      contentHashUri = await resolveENSContentHash(parsedENS.ensName)
    } catch (error) {
      console.debug(`Failed to resolve ENS name: ${parsedENS.ensName}`, error)
      throw new Error(`Failed to resolve ENS name: ${parsedENS.ensName}`)
    }

    let translatedUri
    try {
      translatedUri = contenthashToUri(contentHashUri)
    } catch (error) {
      console.debug('Failed to translate contenthash to URI', contentHashUri)
      throw new Error(`Failed to translate contenthash to URI: ${contentHashUri}`)
    }

    urls = uriToHttp(`${translatedUri}${parsedENS.ensPath ?? ''}`)
  } else {
    if (listUrl.startsWith('local:/')) {
      console.log('loading list locally')
      // Convert `local:/` to a path accessible via fetch
      const fetchUrl = listUrl.replace('local:/', '/') // Assuming `public/token-list/` structure
      try {
        const response = await fetch(fetchUrl)
        if (!response.ok) {
          throw new Error(`Failed to fetch list from ${fetchUrl}`)
        }
        const json = await response.json()
        if (!tokenListValidator(json)) {
          const validationErrors =
            tokenListValidator.errors?.map(error => `${error.dataPath} ${error.message ?? ''}`).join('; ') ??
            'unknown error'
          throw new Error(`Token list failed validation: ${validationErrors}`)
        }
        return json
      } catch (error) {
        throw new Error(`Failed to load local list: ${fetchUrl}. Error: ${error.message}`)
      }
    } else {
      // Handle normal HTTP and IPFS protocols
      urls = uriToHttp(listUrl)
    }
  }

  // Fetch and validate from URLs in the list
  for (let i = 0; i < urls.length; i++) {
    const url = urls[i]
    const isLast = i === urls.length - 1

    let response
    try {
      response = await fetch(url)
    } catch (error) {
      console.debug('Failed to fetch list', listUrl, error)
      if (isLast) throw new Error(`Failed to download list ${listUrl}`)
      continue
    }

    if (!response.ok) {
      if (isLast) throw new Error(`Failed to download list ${listUrl}`)
      continue
    }

    const json = await response.json()
    if (!tokenListValidator(json)) {
      const validationErrors =
        tokenListValidator.errors?.map(error => `${error.dataPath} ${error.message ?? ''}`).join('; ') ??
        'unknown error'
      throw new Error(`Token list failed validation: ${validationErrors}`)
    }

    return json
  }
  throw new Error('Unrecognized list URL protocol.')
}
