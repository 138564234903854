import DialogueModal from 'components/Dialogue/DialogueModal'
import { TDappData } from 'constants/settings/types/TNpcs'
import AddLiquidity from 'pages/AddLiquidity'
import Pool from 'pages/Pool'
import { useAppSettings } from 'providers/AppSettingsProvider/AppSettingsProvider'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Plus } from 'react-feather'
import { Text } from 'rebass'
import styled from 'styled-components'
import baseCurrencies from 'utils/baseCurrencies'
import { ButtonDropdownLight, ButtonPrimary } from '../../components/Button'
import { LightCard } from '../../components/Card'
import { BlueCard } from '../../components/Card'
import { AutoColumn, ColumnCenter } from '../../components/Column'
import CurrencyLogo from '../../components/CurrencyLogo'
import { FindPoolTabs } from '../../components/NavigationTabs'
import { MinimalPositionCard } from '../../components/PositionCard'
import Row from '../../components/Row'
import CurrencySearchModal from '../../components/SearchModal/CurrencySearchModal'
import { PairState, usePair } from '../../data/Reserves'
import { useActiveWeb3React } from '../../hooks'
import { usePairAdder } from '../../state/user/hooks'
import { useTokenBalance } from '../../state/wallet/hooks'
import { TYPE } from '../../theme'
import { currencyId } from '../../utils/currencyId'
import AppBody from '../AppBody'
import { Dots } from '../Pool/styleds'
import { Currency, JSBI, TokenAmount } from '@/lib/sdk/index'

const ResponsiveButtonPrimary = styled(ButtonPrimary)`
  width: fit-content;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 48%;
  `};
`

enum Fields {
  TOKEN0 = 0,
  TOKEN1 = 1
}

interface PoolFinderProps {
  dappData: TDappData
}

export default function PoolFinder({ dappData }: PoolFinderProps) {
  const { account } = useActiveWeb3React()
  const { settings } = useAppSettings()
  const baseCurrency = baseCurrencies(settings.blockchainSettings.currency, settings.wrappedCurrency)[0]

  const [showSearch, setShowSearch] = useState<boolean>(false)
  const [activeField, setActiveField] = useState<number>(Fields.TOKEN1)

  const [showAddLiquidityModal, setShowAddLiquidityModal] = useState(false)
  const closeAddLiquidityModal = () => setShowAddLiquidityModal(false)

  const [showPoolModal, setShowPoolModal] = useState(false)

  const [currency0, setCurrency0] = useState<Currency | null>(baseCurrency)
  const [currency1, setCurrency1] = useState<Currency | null>(null)

  const [pairState, pair] = usePair(dappData, currency0 ?? undefined, currency1 ?? undefined)
  const addPair = usePairAdder()
  useEffect(() => {
    if (pair) {
      addPair(pair)
    }
  }, [pair, addPair])

  const validPairNoLiquidity: boolean =
    pairState === PairState.NOT_EXISTS ||
    Boolean(
      pairState === PairState.EXISTS &&
        pair &&
        JSBI.equal(pair.reserve0.raw, JSBI.BigInt(0)) &&
        JSBI.equal(pair.reserve1.raw, JSBI.BigInt(0))
    )

  const position: TokenAmount | undefined = useTokenBalance(account ?? undefined, pair?.liquidityToken)
  const hasPosition = useMemo(() => {
    return Boolean(position && JSBI.greaterThan(position.raw, JSBI.BigInt(0)))
  }, [position])

  const handleCurrencySelect = useCallback(
    (currency: Currency) => {
      if (activeField === Fields.TOKEN0 && currency0 !== currency) {
        setCurrency0(currency)
      } else if (activeField === Fields.TOKEN1 && currency1 !== currency) {
        setCurrency1(currency)
      }
    },
    [activeField, currency0, currency1]
  )

  const handleSearchDismiss = useCallback(() => {
    setShowSearch(false)
  }, [setShowSearch])

  const prerequisiteMessage = (
    <LightCard padding="45px 10px">
      <Text textAlign="center">
        {!account ? 'Connect to a wallet to find pools' : 'Select a token to find your liquidity.'}
      </Text>
    </LightCard>
  )

  return (
    <AppBody>
      <FindPoolTabs />
      <AutoColumn style={{ padding: '1rem' }} gap="md">
        <BlueCard>
          <AutoColumn gap="10px">
            <TYPE.link fontWeight={400} color={'primaryText1'}>
              <b>Tip:</b> Use this tool to find pairs that don&apos;t automatically appear in the interface.
            </TYPE.link>
          </AutoColumn>
        </BlueCard>
        <ButtonDropdownLight
          onClick={() => {
            setShowSearch(true)
            setActiveField(Fields.TOKEN0)
          }}
        >
          {currency0 ? (
            <Row>
              <CurrencyLogo dappData={dappData} currency={currency0} />
              <Text fontWeight={500} fontSize={20} marginLeft={'12px'}>
                {currency0.symbol}
              </Text>
            </Row>
          ) : (
            <Text fontWeight={500} fontSize={20} marginLeft={'12px'}>
              Select a Token
            </Text>
          )}
        </ButtonDropdownLight>

        <ColumnCenter>
          <Plus size="16" color="#888D9B" />
        </ColumnCenter>

        <ButtonDropdownLight
          onClick={() => {
            setShowSearch(true)
            setActiveField(Fields.TOKEN1)
          }}
        >
          {currency1 ? (
            <Row>
              <CurrencyLogo dappData={dappData} currency={currency1} />
              <Text fontWeight={500} fontSize={20} marginLeft={'12px'}>
                {currency1.symbol}
              </Text>
            </Row>
          ) : (
            <Text fontWeight={500} fontSize={20} marginLeft={'12px'}>
              Select a Token
            </Text>
          )}
        </ButtonDropdownLight>

        {hasPosition && (
          <ColumnCenter
            style={{
              justifyItems: 'center',
              backgroundColor: '',
              padding: '12px 0px',
              borderRadius: '12px'
            }}
          >
            <Text textAlign="center" fontWeight={500}>
              Pool Found!
            </Text>
            <ResponsiveButtonPrimary
              padding="6px 8px"
              onClick={() => setShowPoolModal(true)} // Open Create Pool Modal
            >
              Manage this pool
            </ResponsiveButtonPrimary>
          </ColumnCenter>
        )}

        {showPoolModal && (
          <DialogueModal isOpen={showPoolModal} onClose={() => setShowPoolModal(false)} title="Manage Pool">
            <Pool dappData={dappData} />
          </DialogueModal>
        )}

        {showAddLiquidityModal && (
          <DialogueModal isOpen={showAddLiquidityModal} onClose={closeAddLiquidityModal} title="Add Liquidity">
            <AddLiquidity
              currencyIdA={currencyId(currency0, baseCurrency, settings.blockchainSettings.currency)}
              currencyIdB={currencyId(currency1, baseCurrency, settings.blockchainSettings.currency)}
              isCreate={false}
              dappData={dappData}
            />
          </DialogueModal>
        )}

        {currency0 && currency1 ? (
          pairState === PairState.EXISTS ? (
            hasPosition && pair ? (
              <MinimalPositionCard dappData={dappData} pair={pair} border="1px solid #CED0D9" />
            ) : (
              <LightCard padding="45px 10px">
                <AutoColumn gap="sm" justify="center">
                  <Text textAlign="center">{`You don't have liquidity in this pool yet.`}</Text>
                  <ResponsiveButtonPrimary padding="6px 8px" onClick={() => setShowAddLiquidityModal(true)}>
                    Add Liquidity
                  </ResponsiveButtonPrimary>
                </AutoColumn>
              </LightCard>
            )
          ) : validPairNoLiquidity ? (
            <LightCard padding="45px 10px">
              <AutoColumn gap="sm" justify="center">
                <Text textAlign="center">No pool found.</Text>
                <ResponsiveButtonPrimary
                  padding="6px 8px"
                  onClick={() => setShowAddLiquidityModal(true)} // Open Create Pool Modal
                >
                  Create a pair
                </ResponsiveButtonPrimary>

                {showAddLiquidityModal && (
                  <DialogueModal
                    isOpen={showAddLiquidityModal}
                    onClose={() => setShowAddLiquidityModal(false)}
                    title="Create Pool"
                  >
                    <AddLiquidity
                      currencyIdA={currencyId(currency0, baseCurrency, settings.blockchainSettings.currency)}
                      currencyIdB={currencyId(currency1, baseCurrency, settings.blockchainSettings.currency)}
                      isCreate={false}
                      dappData={dappData}
                    />
                  </DialogueModal>
                )}
              </AutoColumn>
            </LightCard>
          ) : pairState === PairState.INVALID ? (
            <LightCard padding="45px 10px">
              <AutoColumn gap="sm" justify="center">
                <Text textAlign="center" fontWeight={500}>
                  Invalid pair.
                </Text>
              </AutoColumn>
            </LightCard>
          ) : pairState === PairState.LOADING ? (
            <LightCard padding="45px 10px">
              <AutoColumn gap="sm" justify="center">
                <Text textAlign="center">
                  Loading
                  <Dots />
                </Text>
              </AutoColumn>
            </LightCard>
          ) : null
        ) : (
          prerequisiteMessage
        )}
      </AutoColumn>

      <CurrencySearchModal
        isOpen={showSearch}
        onCurrencySelect={handleCurrencySelect}
        onDismiss={handleSearchDismiss}
        showCommonBases
        selectedCurrency={(activeField === Fields.TOKEN0 ? currency1 : currency0) ?? undefined}
        dappData={dappData}
      />
    </AppBody>
  )
}
