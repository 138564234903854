import { TDappData } from 'constants/settings/types/TNpcs'
import { useAppSettings } from 'providers/AppSettingsProvider/AppSettingsProvider'
import { useMemo } from 'react'
import { useActiveWeb3React } from './index'
import { usePair } from '../data/Reserves'
import { Token, Price } from '@/lib/sdk/index'

export default function useTokenWETHPrice(token: Token | undefined, dappData: TDappData): Price | undefined {
  const { chainId } = useActiveWeb3React()
  const { settings } = useAppSettings()
  const [, tokenWETHPair] = usePair(dappData, chainId && settings.wrappedCurrency, token)

  return useMemo(() => {
    return token && chainId && tokenWETHPair ? tokenWETHPair.priceOf(token) : undefined
  }, [chainId, token, tokenWETHPair])
}
