import { NPC } from 'constants/settings/types/TNpcs'
import { EDirection } from 'game/playerCharacter/addPlayer'
import { isPlayerNearbyAndFacing } from 'game/playerCharacter/utils/isPlayerNearbyAndFacing'
import { NPCAnimatedSprite } from 'game/types/NPCAnimatedSprite'
import { Application, extras, Container } from 'pixi.js'

export function enableDialogueForNearestNPC(
  app: Application,
  lastDirection: EDirection,
  playerSprite: extras.AnimatedSprite,
  keys: { [key: string]: boolean },
  setShowDialogue: React.Dispatch<React.SetStateAction<boolean>>,
  setDialogueData: React.Dispatch<React.SetStateAction<NPC | null>>
) {
  if (!app?.stage?.children || app.stage.children.length === 0) {
    return
  }

  const allNPCs = app.stage.children.filter(child => child.name === 'npcs') as Container[]
  for (const npcContainer of allNPCs) {
    for (const npc of npcContainer.children) {
      const npcSprite = npc as NPCAnimatedSprite

      if (isPlayerNearbyAndFacing(playerSprite, npcSprite, lastDirection) && (keys['KeyF'] || keys['Enter'])) {
        if (npcSprite.NPCData?.dialogue) {
          setDialogueData({
            ...npcSprite.NPCData
          })
          setShowDialogue(true)
        }
      }
    }
  }
}
