import { useState, useCallback, useEffect } from 'react'

interface AlertState {
  message: string
  success: boolean
  link?: string
  linkText?: string
  autoCloseAfterMs?: number | null // Optional auto close after a time
}

export function useAlert() {
  const [alert, setAlertState] = useState<AlertState | null>(null)

  // Function to set an alert
  const setAlert = useCallback(
    (message: string, success: boolean, link?: string, linkText?: string, autoCloseAfterMs?: number | null) => {
      setAlertState({ message, success, link, linkText, autoCloseAfterMs })
    },
    []
  )

  // Function to clear the alert
  const clearAlert = useCallback(() => {
    setAlertState(null)
  }, [])

  // Auto-close functionality
  useEffect(() => {
    if (alert && alert.autoCloseAfterMs) {
      const timeout = setTimeout(() => {
        clearAlert()
      }, alert.autoCloseAfterMs)

      // Cleanup the timeout when alert changes or is closed
      return () => clearTimeout(timeout)
    }
    return
  }, [alert, clearAlert])

  return { alert, setAlert, clearAlert }
}
