import { NPC } from 'constants/settings/types/TNpcs'
import { NPCAnimatedSprite } from 'game/types/NPCAnimatedSprite'
import { extras, Texture, Application, Container } from 'pixi.js'
import { moveWithCollision } from './moveWithCollision'
import { updateIdleAnimation } from './npcIdleAnimation'

export function enableNPCMovement(
  npcSprite: NPCAnimatedSprite,
  moveToLocation: [number, number],
  npcData: NPC,
  rows: Texture[][],
  app: Application
) {
  if (!npcSprite) {
    return
  }
  const speed = npcData.npcBehavior.speed || 1
  let moving = true
  let reverse = false
  let lastDirection = ''

  const startLocation = [npcSprite.x, npcSprite.y]
  let targetLocation = moveToLocation

  const originalWidth = npcSprite.width
  const originalHeight = npcSprite.height

  app.ticker.add(() => {
    if (moving) {
      let directionChanged = false
      let isMoving = false

      const otherContainers = app.stage.children.filter(child => child.name !== 'background') as Container[]

      const otherSprites = otherContainers.reduce((acc: extras.AnimatedSprite[], container) => {
        const animatedSprites = container.children.filter(
          (child): child is extras.AnimatedSprite => child instanceof extras.AnimatedSprite && child !== npcSprite
        )
        return acc.concat(animatedSprites)
      }, [] as extras.AnimatedSprite[])

      // remove current npc sprite from otherSprites
      const index = otherSprites.findIndex(sprite => sprite === npcSprite)
      if (index > -1) {
        otherSprites.splice(index, 1)
      }

      moveWithCollision(
        npcSprite,
        targetLocation,
        speed,
        otherSprites.map(sprite => ({ x: sprite.x, y: sprite.y })),
        app
      )

      if (npcSprite.x < targetLocation[0]) {
        if (lastDirection !== 'right') {
          lastDirection = 'right'
          directionChanged = true
        }
        isMoving = true
      } else if (npcSprite.x > targetLocation[0]) {
        if (lastDirection !== 'left') {
          lastDirection = 'left'
          directionChanged = true
        }
        isMoving = true
      }

      if (npcSprite.y < targetLocation[1]) {
        if (lastDirection !== 'down') {
          lastDirection = 'down'
          directionChanged = true
        }
        isMoving = true
      } else if (npcSprite.y > targetLocation[1]) {
        if (lastDirection !== 'up') {
          lastDirection = 'up'
          directionChanged = true
        }
        isMoving = true
      }

      // Update the sprite animation if direction changes or movement happens
      if (directionChanged) {
        if (lastDirection === 'left') {
          npcSprite.textures = rows[4]
          npcSprite.scale.x = npcData.hasLeftFacingSprites ? 1 : -1
        } else if (lastDirection === 'right') {
          npcSprite.textures = rows[4]
          npcSprite.scale.x = npcData.hasLeftFacingSprites ? -1 : 1
        } else if (lastDirection === 'up') {
          npcSprite.textures = rows[5]
          npcSprite.scale.x = 1
        } else if (lastDirection === 'down') {
          npcSprite.textures = rows[3]
          npcSprite.scale.x = 1
        }
        npcSprite.play()
      }

      // Preserve original size
      npcSprite.width = originalWidth
      npcSprite.height = originalHeight

      if (Math.abs(npcSprite.x - targetLocation[0]) < 1 && Math.abs(npcSprite.y - targetLocation[1]) < 1) {
        moving = false
        npcSprite.x = targetLocation[0]
        npcSprite.y = targetLocation[1]

        updateIdleAnimation(npcSprite, lastDirection, rows)

        // Pause before reversing direction
        setTimeout(() => {
          reverse = !reverse
          targetLocation = reverse ? startLocation : moveToLocation
          moving = true
        }, 4000)
      }

      // If no movement, switch to idle animation
      if (!isMoving && !moving) {
        updateIdleAnimation(npcSprite, lastDirection, rows)
      }
    }
  })

  app.ticker.start()
}
