import styled from 'styled-components'

// Styled components for the dropdown
export const DropdownWrapper = styled.div`
  position: relative;
  display: inline-block;
`

export const DropdownButton = styled.button`
  background-color: #40444f;
  border: 1px solid ${({ theme }) => theme.primary5};
  padding: 0.75rem 1.25rem;
  border-radius: 12px;
  cursor: pointer;
  font-size: 1rem;
  display: flex;
  align-items: center;
  color: #fff;
  justify-content: space-between;
  width: 100px;
`

export const DropdownContent = styled.div`
  display: ${(props: { isOpen: boolean }) => (props.isOpen ? 'flex' : 'none')};
  position: absolute;
  flex-direction: column;
  background-color: #2c2f36;
  bottom: 100%;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  margin-top: 0.5rem;
  z-index: 1;
  max-height: 300px;
  overflow-y: auto;
  margin-bottom: 10px;
`

export const DropdownItem = styled.div<{ isSelected: boolean }>`
  padding: 0.75rem 1.25rem;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  color: ${({ isSelected }) => (isSelected ? '#fff' : '#8a8f98')};
  background-color: ${({ isSelected }) => (isSelected ? '#3a82f7' : 'transparent')};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  max-width: 330px;
  min-width: 330px;

  &:hover {
    background-color: ${({ isSelected }) => (isSelected ? '#3a82f7' : '#353840')};
    color: #fff;
  }
`

export const ConnectedLabel = styled.span`
  background-color: #1fd984;
  color: #fff;
  font-size: 0.75rem;
  padding: 0.2rem 0.5rem;
  border-radius: 8px;
`
