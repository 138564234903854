import React, { useEffect, useState } from 'react'
import { ArrowDownCircle } from 'react-feather'
import { BannerContainer, ToggleButton, RingingBell } from './WarningBanner.styles'

// Warning banner component that takes a list of warning strings
interface WarningBannerProps {
  warnings?: string[]
  showWarning: boolean
}

// Warning strings (default)
const warningMessages = [
  "Welcome, explorers! Our GUI is evolving, and we're excited to share the progress as we continue to improve it.",
  "Hey, adventurers! We're still fine-tuning our GUI, and we appreciate your patience as we make things better.",
  'Greetings, travelers! Our GUI is undergoing some updates—stay tuned for a smoother, more intuitive experience soon!',
  "Hello, intrepid explorers! Our GUI is getting a makeover, and we're excited for you to see the improvements coming your way.",
  'Welcome, fellow adventurers! Our GUI is still a work in progress, but big enhancements are on the horizon!'
]

const WarningBanner: React.FC<WarningBannerProps> = ({ warnings, showWarning }) => {
  const [randomWarning, setRandomWarning] = useState('')
  const [expanded, setExpanded] = useState(false) // State to track expansion
  const displayWarnings = warnings && warnings.length > 0 ? warnings : warningMessages

  const toggleExpand = () => {
    setExpanded(prev => !prev) // Toggle between expanded and collapsed
  }

  // Randomize the warning message when the component mounts
  useEffect(() => {
    if (showWarning) {
      const randomIndex = Math.floor(Math.random() * displayWarnings.length)
      setRandomWarning(displayWarnings[randomIndex])
    }
  }, [warnings, showWarning])

  // Only render the warning banner if showWarning is true
  if (!showWarning) {
    return null
  }

  return (
    <>
      {/* Banner Container */}
      <BannerContainer expanded={expanded}>
        {expanded && <div>{randomWarning}</div>} {/* Show content only when expanded */}
      </BannerContainer>

      {/* Toggle Button */}
      <ToggleButton expanded={expanded} onClick={toggleExpand}>
        <RingingBell expanded={expanded} />
        {expanded ? <ArrowDownCircle /> : null} {/* Toggle icon based on expanded state */}
      </ToggleButton>
    </>
  )
}

export default WarningBanner
