import React from 'react'
import { Bell } from 'react-feather'
import styled, { keyframes, css } from 'styled-components'

// Ringing animation for the bell
export const ring = keyframes`
  0% { transform: rotate(0); }
  10% { transform: rotate(15deg); }
  20% { transform: rotate(-15deg); }
  30% { transform: rotate(10deg); }
  40% { transform: rotate(-10deg); }
  50% { transform: rotate(5deg); }
  60% { transform: rotate(-5deg); }
  100% { transform: rotate(0); }
`

// Styled component for the banner
export const BannerContainer = styled.div<{ expanded: boolean }>`
  position: fixed;
  top: 150px;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  font-weight: bold;
  font-family: 'Arial', sans-serif;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: height 0.3s ease-in-out, padding 0.3s ease-in-out, opacity 0.3s ease-in-out;
  height: ${props => (props.expanded ? 'auto' : '0')}; /* Set height to 0 when collapsed */
  padding: ${props => (props.expanded ? '10px' : '0')}; /* No padding when collapsed */
  opacity: ${props => (props.expanded ? '1' : '0')}; /* Fade out when collapsed */
  overflow: hidden; /* Hide content when collapsed */
`

// Styled component for the toggle button (expand/collapse)
export const ToggleButton = styled.button<{ expanded: boolean }>`
  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  color: white;
  font-size: 18px;
  cursor: pointer;
  position: fixed;
  top: 25px
  left: 25px;
  z-index: 1001; /* Ensure it's above other elements */
  &:hover {
    color: ${({ theme }) => theme.primary1};
  }
`

// Bell icon with ringing animation when collapsed, without passing the expanded prop to the Bell component
export const RingingBell = styled(({ expanded, ...rest }) => <Bell {...rest} />)<{ expanded: boolean }>`
  ${({ expanded }) =>
    !expanded &&
    css`
      color: #ff5722;
      animation: ${ring} 1.5s ease-in-out infinite;
      animation-delay: 3s;
    `}
`
