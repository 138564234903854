import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #2d2d3a;
  color: #e0e0e0;
  border-radius: 10px;
  width: 400px;
  margin: 0 auto;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 90%;
    padding: 10px;
  `};
`

const Title = styled.h2`
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 20px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
  font-size: 20px;
  margin-bottom: 15px;
  `};
`

const InstructionsList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`

const InstructionItem = styled.li`
  font-size: 16px;
  margin-bottom: 10px;
  &::before {
    content: '•';
    margin-right: 8px;
    color: #fff;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
 size: 14px;
 margin-bottom: 8px;
 `};
`

export const ControlsInstructions: React.FC = () => {
  return (
    <Container>
      <Title>Controls</Title>
      <InstructionsList>
        <InstructionItem>AD / left and right arrow keys = move to the left and right.</InstructionItem>
        <InstructionItem>WS / up and down arrow keys = move up or down. </InstructionItem>
        <InstructionItem>F or Enter = Interact (when available)</InstructionItem>
      </InstructionsList>
    </Container>
  )
}
