import { extras } from 'pixi.js'
import { EDirection } from '../addPlayer'

// Define the distance threshold for interaction
const INTERACTION_DISTANCE = 50

// Function to check if the player is nearby and facing the NPC
export function isPlayerNearbyAndFacing(
  player: extras.AnimatedSprite,
  npc: extras.AnimatedSprite,
  playerDirection: EDirection
): boolean {
  const distance = Math.sqrt(Math.pow(player.x - npc.x, 2) + Math.pow(player.y - npc.y, 2))

  if (distance > INTERACTION_DISTANCE) {
    return false
  }

  // Check if the player is facing the NPC
  if (playerDirection === EDirection.RIGHT && player.x < npc.x) {
    return true
  } else if (playerDirection === EDirection.LEFT && player.x > npc.x) {
    return true
  } else if (playerDirection === EDirection.UP && player.y > npc.y) {
    return true
  } else if (playerDirection === EDirection.DOWN && player.y < npc.y) {
    return true
  }

  return false
}
