import styled from 'styled-components'

export const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme, active }) => (!active ? theme.bg1 : theme.bg3)};
  border-radius: 12px;
  white-space: nowrap;
  width: 100%;
  max-width: 800px;
  cursor: pointer;

  :focus {
    border: 1px solid blue;
  }
`

// Styling for the footer label
export const FooterContainer = styled.div`
  position: fixed;
  display: flex;
  flex-direction: row;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  padding: 10px 20px;
  border-radius: 10px;s
  font-size: 14px;
  font-family: 'Press Start 2P', /* Retro-style font */
  z-index: 1000;
  white-space: nowrap;
  align-items: center;
`
