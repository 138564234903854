import { getAddress } from '@ethersproject/address'
import { BigNumber } from '@ethersproject/bignumber'
import { AddressZero } from '@ethersproject/constants'
import { Contract } from '@ethersproject/contracts'
import { JsonRpcSigner, Web3Provider } from '@ethersproject/providers'
import { ChainId, DEFAULT_CURRENCY } from 'constants/settings'
import { TDappData } from 'constants/settings/types/TNpcs'
import { TSettings } from 'constants/settings/types/TSettings'
import isEqual from 'lodash.isequal'
import { abi as IUniswapV2Router02ABI } from '../constants/abis/contracts/IUniswapV2Router02.json'
import { useActiveWeb3React } from '../hooks/index'
import { TokenAddressMap } from '../state/lists/hooks'
import { JSBI, Percent, Token, CurrencyAmount, Currency } from '@/lib/sdk/index'

// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value: any): string | false {
  try {
    return getAddress(value)
  } catch {
    return false
  }
}

/** @deprecated @remarks - have to mayke this dynamic in another ticket */
export function getEtherscanLink(
  chainId: ChainId,
  data: string,
  type: 'transaction' | 'token' | 'address' | 'block',
  settings: TSettings
): string {
  const prefix = settings.etherscanPrefix

  switch (type) {
    case 'transaction': {
      if (chainId === ChainId.BUILD_BEAR) {
        return `${prefix}/transactions/${data}`
      }
      return `${prefix}/tx/${data}`
    }
    case 'token': {
      return `${prefix}/token/${data}`
    }
    case 'block': {
      return `${prefix}/block/${data}`
    }
    case 'address':
    default: {
      return `${prefix}/address/${data}`
    }
  }
}

// shorten the checksummed version of the input address to have 0x + 4 characters at start and end
export function shortenAddress(address: string, chars = 8): string {
  const parsed = isAddress(address)
  if (!parsed) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }
  return `${parsed.substring(0, chars + 2)}...${parsed.substring(42 - chars)}`
}

// add 10%
export function calculateGasMargin(value: BigNumber): BigNumber {
  return value.mul(BigNumber.from(10000).add(BigNumber.from(1000))).div(BigNumber.from(10000))
}

// converts a basis points value to a sdk percent
export function basisPointsToPercent(num: number): Percent {
  return new Percent(JSBI.BigInt(num), JSBI.BigInt(10000))
}

export function calculateSlippageAmount(value: CurrencyAmount, slippage: number): [JSBI, JSBI] {
  if (slippage < 0 || slippage > 10000) {
    throw Error(`Unexpected slippage value: ${slippage}`)
  }
  return [
    JSBI.divide(JSBI.multiply(value.raw, JSBI.BigInt(10000 - slippage)), JSBI.BigInt(10000)),
    JSBI.divide(JSBI.multiply(value.raw, JSBI.BigInt(10000 + slippage)), JSBI.BigInt(10000))
  ]
}

// account is not optional
export function getSigner(library: Web3Provider, account: string): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked()
}

// account is optional
export function getProviderOrSigner(library: Web3Provider, account?: string): Web3Provider | JsonRpcSigner {
  return account ? getSigner(library, account) : library
}

// account is optional
export function getContract(address: string, ABI: any, library: Web3Provider, account?: string): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }

  return new Contract(address, ABI, getProviderOrSigner(library, account) as any)
}

// account is optional
export function getRouterContract(
  chainId: number,
  library: Web3Provider,
  routerAddr: string,
  account?: string
): Contract {
  const routerAddress = (chainId && routerAddr) as string
  return getContract(routerAddress, IUniswapV2Router02ABI, library, account)
}

export function useRouterContractAddress(dappData: TDappData): string | undefined {
  const { chainId } = useActiveWeb3React()
  const routerAddress = (chainId && dappData.routerAddress) as string
  return routerAddress
}

export function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

export function isTokenOnList(nativeCurrency: Currency, defaultTokens: TokenAddressMap, currency?: Currency): boolean {
  if (currency && [nativeCurrency, DEFAULT_CURRENCY].some(item => isEqual(item, currency))) return true
  return Boolean(currency instanceof Token && defaultTokens[currency.chainId as ChainId]?.[currency.address])
}

export function getPairContract(address: string, ABI: any, library: Web3Provider, account?: string): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }

  return new Contract(address, ABI, getProviderOrSigner(library, account) as any)
}
