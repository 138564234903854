import { DEFAULT_CURRENCY } from 'constants/settings'
import isEqual from 'lodash.isequal'
import { MIN_ETH } from '../constants'
import { CurrencyAmount, JSBI, Currency } from '@/lib/sdk/index'

/**
 * Given some token amount, return the max that can be spent of it
 * @param nativeCurrency - native block currency
 * @param currencyAmount to return max of\
 */
export function maxAmountSpend(nativeCurrency: Currency, currencyAmount?: CurrencyAmount): CurrencyAmount | undefined {
  if (!currencyAmount) return undefined

  if (
    currencyAmount.currency &&
    [nativeCurrency, DEFAULT_CURRENCY].some(item => isEqual(item, currencyAmount.currency))
  ) {
    if (JSBI.greaterThan(currencyAmount.raw, MIN_ETH)) {
      return CurrencyAmount.ether(JSBI.subtract(currencyAmount.raw, MIN_ETH))
    } else {
      return CurrencyAmount.ether(JSBI.BigInt(0))
    }
  }
  return currencyAmount
}
