import { NPC } from 'constants/settings/types/TNpcs'
import { enableDialogueForNearestNPC } from 'game/dialogue/enableDialogueForNearestNPC'
import { Application, loader, extras, Container } from 'pixi.js'
import { handlePlayerMovement } from './utils/handlePlayerMovement'
import { initializePlayerSprite } from './utils/initializePlayerSprite'
import { loadPlayerTextures } from './utils/loadPlayerTextures'
import { updateMovement as updateMovementAnimation } from './utils/updateMovement'

export enum EDirection {
  UP = 0,
  DOWN = 1,
  LEFT = 2,
  RIGHT = 3
}

const centerCameraOnPlayer = (app: Application, player: PIXI.Sprite) => {
  const centerX = app.renderer.width / 2
  const centerY = app.renderer.height / 2
  app.stage.pivot.set(player.x, player.y)
  app.stage.position.set(centerX, centerY)
}

export function addPlayer(
  app: Application,
  playerContainer: Container,
  playerImgPath: string,
  setShowDialogue: React.Dispatch<React.SetStateAction<boolean>>,
  setDialogueData: React.Dispatch<React.SetStateAction<NPC | null>>,
  startTile: { x: number; y: number },
  mobileKeys: { [key: string]: boolean }
): () => void {
  let onKeyDown: any = null
  let onKeyUp: any = null
  let onTouchStart: any = null
  let onTouchEnd: any = null
  let animatedSprite: extras.AnimatedSprite
  let tickerCallback: any = null

  loader.add('playerSheet', playerImgPath).load((_, resources) => {
    const frameWidth = 48
    const frameHeight = 48
    const numColumns = 4
    const numRows = 12
    const speed = 3

    const rows = loadPlayerTextures(resources, frameWidth, frameHeight, numColumns, numRows)
    animatedSprite = initializePlayerSprite(app, rows, playerContainer, startTile)
    const keys: { [key: string]: boolean } = {}

    let lastDirection: EDirection = EDirection.DOWN

    const updateMergedKeys = () => {
      const mergedKeys: { [key: string]: boolean } = {}

      for (const key in keys) {
        mergedKeys[key] = keys[key] || mobileKeys[key] || false
      }

      for (const key in mobileKeys) {
        mergedKeys[key] = mergedKeys[key] || mobileKeys[key]
      }

      return mergedKeys
    }

    tickerCallback = () => {
      const mergedKeys = updateMergedKeys()
      lastDirection = updateMovementAnimation(animatedSprite, mergedKeys, lastDirection, rows, speed)
      handlePlayerMovement(app, animatedSprite, mergedKeys, speed)
    }

    app.ticker.add(tickerCallback)

    onKeyDown = (event: KeyboardEvent) => {
      keys[event.code] = true
      updateMovementAnimation(animatedSprite, updateMergedKeys(), lastDirection, rows, speed)
      enableDialogueForNearestNPC(app, lastDirection, animatedSprite, keys, setShowDialogue, setDialogueData)
    }

    onKeyUp = (event: KeyboardEvent) => {
      keys[event.code] = false
      updateMovementAnimation(animatedSprite, updateMergedKeys(), lastDirection, rows, speed)
    }

    onTouchStart = (event: TouchEvent) => {
      const keyMapping: { [key: string]: string } = {
        KeyW: 'KeyW',
        KeyA: 'KeyA',
        KeyS: 'KeyS',
        KeyD: 'KeyD',
        KeyF: 'KeyF',
        Escape: 'Escape'
      }

      const target = event.target as HTMLElement
      const touchKey = target?.getAttribute('data-value')
      if (touchKey && keyMapping[touchKey]) {
        keys[keyMapping[touchKey]] = true
        updateMovementAnimation(animatedSprite, updateMergedKeys(), lastDirection, rows, speed)
        enableDialogueForNearestNPC(app, lastDirection, animatedSprite, keys, setShowDialogue, setDialogueData)
      }
    }

    onTouchEnd = (event: TouchEvent) => {
      const keyMapping: { [key: string]: string } = {
        KeyW: 'KeyW',
        KeyA: 'KeyA',
        KeyS: 'KeyS',
        KeyD: 'KeyD',
        KeyF: 'KeyF',
        Escape: 'Escape'
      }

      const target = event.target as HTMLElement
      const touchKey = target?.getAttribute('data-value')
      if (touchKey && keyMapping[touchKey]) {
        keys[keyMapping[touchKey]] = false
        updateMovementAnimation(animatedSprite, updateMergedKeys(), lastDirection, rows, speed)
      }
    }

    addEventListener('keydown', onKeyDown)
    addEventListener('keyup', onKeyUp)
    addEventListener('touchstart', onTouchStart)
    addEventListener('touchend', onTouchEnd)

    centerCameraOnPlayer(app, animatedSprite)
    app.ticker.add(() => {
      centerCameraOnPlayer(app, animatedSprite)
    })
  })

  return () => {
    console.log('start cleaning up playersheet')
    app.stop()
    loader.removeAllListeners()
    loader.reset()
    console.log('finished cleaning up playersheet')
  }
}
