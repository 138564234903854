import { Blockchain } from 'constants/settings'
import { Token, Currency } from '@/lib/sdk/index'
import { ChainTokenList } from '@/lib/sdk-extra/chainTokenList'

// Defines the image assets for the platform, including background and logos
export type TPlatformBaseImageAssets = {
  appBgImg: string // Background image of the application
  appLogoWhite: string // White logo used in light themes
  appLogoDark: string // Dark logo used in dark themes
  appBgCard: string // Background image for cards or panels
}

// Represents a token with its basic properties
export type Ttoken = {
  address: string // Token contract address
  name: string // Name of the token
  symbol: string // Symbol of the token (e.g., ETH, BTC)
  decimal: number // Number of decimals used by the token
}

export enum EBackgroundTheme {
  STAR = 'STAR',
  BUBBLE = 'BUBBLE'
}

// Configuration settings for the platform's blockchain integration
export type TSettings = {
  platformBackgroundTheme?: EBackgroundTheme // we only support a few themes to draw using pixijs background canvas
  platformLinks?: SocialLink[]
  platformName: string // Name of the blockchain application/platform
  platformImg: TPlatformBaseImageAssets // Image assets that define the platform's appearance
  blockchainSettings: TBlockChainSettings // Settings specific to the connected blockchain
  etherscanPrefix: string
  webInterfaces: string[] // List of domain names where the app is hosted
  multiCallAddr: string // Multicall contract address for batch contract calls; must support Uniswap V2 Contracts
  liquidityPair: Partial<Ttoken> // Represents the liquidity pool token (pair)
  // factoryAddr: string // Address of the factory contract that creates pairs
  // initHash: string // Initialization hash used in factory to pair creation
  wrappedCurrency: Token // Wrapped version of the native blockchain currency (e.g., WETH for ETH)
  suggestedBases: Token[] // Default tokens suggested when adding liquidity
  basesToTrackLiquidityFor: Token[] // Tokens used to determine which liquidity pairs to track in the frontend
  pinnedPairs: [Token, Token][] | undefined // Specific token pairs to prioritize in the UI
  // customBases: { [tokenAddress: string]: Token[] } | undefined // Custom base tokens set for specific tokens
  basesToCheckTradesAgainst: Token[] // Tokens used as intermediary pairs for routing trades
  merkleDistributorAddr: string | undefined // Address of the Merkle Distributor for token distributions
  stablePriceTokenToOverride?: Token // Affected files: useStablePrice, PoolCard, useTokensWithWETHPrice.ts, useStakingInfo(), determineBaseToken, calculateWethAdjustedTotalStakedAmount
  // These files collectively impact the calculation of APR for farms and the method used to determine the price of STABLE tokens (e.g., BUSD, USD, DAI).
  // Currently, the STABLE token price is assumed to have a 1:1 value ratio with its base (e.g., USD).
  // Any changes to this assumption or token override logic may require updates across the listed files.

  chainTokenList: ChainTokenList // Chain token list object for the current chain
}

// Defines the settings for a specific blockchain used within the platform
export type TBlockChainSettings = {
  chainId: number // Unique chain identifier (e.g., 1 for Ethereum Mainnet)
  hexChainId: string // Hexadecimal representation of the chain ID
  blockchain: Blockchain // Enum representing the blockchain (used for logic and display)
  name?: string // Display name of the blockchain (e.g., "Polygon Mainnet")
  rpcURLs: string[] // List of RPC URLs for connecting to the blockchain
  rpcAPIKey?: string // Optional API key for accessing the RPC endpoints
  explorerURL?: string // URL of the blockchain explorer for viewing transactions and contracts
  blockTime?: number // Average time between blocks in seconds, used for time calculations
  currency: Currency // Native currency object for the blockchain (e.g., MATIC for Polygon)
}

export type SocialLink = {
  label: string
  url: string
}
