import { ChainId } from 'constants/settings'
import { StakingRewardsInfo } from 'hooks/useStakingRewardsInfo'
import { useMemo } from 'react'

export default function useCalculateStakingInfoMembers(
  chainId: ChainId | undefined,
  stakingInfo: StakingRewardsInfo[]
): Record<string, number | undefined> {
  return useMemo(() => {
    const activeStakingInfos = chainId ? stakingInfo?.filter(stakingRewardInfo => stakingRewardInfo.active) : []
    const inactiveStakingInfos = chainId ? stakingInfo?.filter(stakingRewardInfo => !stakingRewardInfo.active) : []

    return {
      active: activeStakingInfos?.length,
      inactive: inactiveStakingInfos?.length
    }
  }, [chainId])
}
