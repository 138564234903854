import styled from 'styled-components'

// Menu container styles
export const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 300px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 0 10px;
  `};
`

// Menu button styles
export const MenuButton = styled.a`
  color: #fff;
  font-size: 16px;
  border: 1px solid #fff;
  padding: 15px 60px;
  margin: 20px 0;
  position: relative;
  cursor: pointer;
  outline: none;
  text-transform: uppercase;
  -webkit-transition: -webkit-transform 0.1s ease-in-out;
  -webkit-transition: transform 0.1s ease-in-out;
  transition: transform 0.1s ease-in-out;
  min-width: 300px;
  max-width: 300px;
  background-color: #2d2d3a;
  text-decoration: none;
  text-align: center;

  &:hover {
    transform: scale(1.05); /* Slightly enlarge on hover */
  }

  &:active {
    transform: scale(0.98); /* Slightly shrink when clicked */
  }

  /* Red corner highlights only appear on hover */
  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    background-color: transparent;
    transition: background-color 0.2s ease-in-out;
  }

  &::before {
    top: -8px;
    left: -8px;
    clip-path: polygon(0 0, 100% 0, 0 100%);
  }

  &::after {
    bottom: -8px;
    right: -8px;
    clip-path: polygon(100% 0, 100% 100%, 0 100%);
  }

  &:hover::before,
  &:hover::after {
    border: 1px solid #ff3030; /* Red corner on hover */
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 10px 20px;
    min-width: 75%;
    font-size: 14px;
  `};
`
