import { DEFAULT_CURRENCY } from 'constants/settings'
import { ChainId } from 'constants/settings'
import isEqual from 'lodash.isequal'
import baseCurrencies from '../utils/baseCurrencies'
import { Currency, CurrencyAmount, Token, TokenAmount } from '@/lib/sdk/index'

/**
 * Returns the wrapped version of a currency (WETH) if the currency is a default base currency for the given chain.
 *
 * @param currency - The currency to potentially wrap. Can be any type but is usually a `Currency` or `Token`.
 * @param chainId - The ID of the blockchain network to determine the appropriate wrapped token (WETH).
 * @param nativeCurrency - The native currency of the chain where the pairs exist (e.g., ETH, BNB).
 * @returns The wrapped token (WETH) if the currency is a default currency and the chainId is valid; otherwise, returns the original currency or `undefined`.
 */
export function wrappedCurrency(
  currency: any | undefined,
  chainId: ChainId | undefined,
  nativeCurrency: Currency | undefined,
  wrappedCurrency: Token
): Token | undefined {
  if (chainId && currency && [nativeCurrency, DEFAULT_CURRENCY].some(item => isEqual(item, currency))) {
    return wrappedCurrency
  }

  if (currency) {
    return currency
  }

  return undefined
}

/**
 * Returns a `TokenAmount` representation of a given `CurrencyAmount`, wrapping the currency into its corresponding token
 * (e.g., converting ETH to WETH) if applicable. If the currency amount is undefined or cannot be wrapped, it returns undefined.
 *
 * @param currencyAmount - The amount of the currency to be wrapped. This is typically an instance of `CurrencyAmount`.
 * @param chainId - The blockchain network ID where the wrapping occurs. This determines the wrapped token to be used.
 * @param nativeCurrency - The native currency of the chain (e.g., ETH, BNB) to help determine the correct wrapped token.
 *
 * @returns A `TokenAmount` representing the wrapped version of the currency amount, or `undefined` if the wrapping cannot be performed.
 */
export function wrappedCurrencyAmount(
  currencyAmount: CurrencyAmount | undefined,
  chainId: ChainId | undefined,
  nativeCurrency: Currency | undefined,
  wrappedCurrencyToken: Token
): TokenAmount | undefined {
  const token =
    currencyAmount && chainId
      ? wrappedCurrency(currencyAmount.currency, chainId, nativeCurrency, wrappedCurrencyToken)
      : undefined
  return token && currencyAmount ? new TokenAmount(token, currencyAmount.raw) : undefined
}

/**
 * Returns the unwrapped version of a token if it matches the WETH (Wrapped Ether) for the given chain.
 *
 * @param token - The token to potentially unwrap. This should be an instance of `Token`.
 * @param nativeCurrency - the native blockchain token
 * @param wrappedCurrency - the wrapped native token
 * @returns The base currency of the chain if the token is WETH, otherwise returns the original token.
 */
export function unwrappedToken(token: Token, nativeCurrency: Currency, wrappedCurrency: Token): Currency {
  if (token.equals(wrappedCurrency)) return baseCurrencies(nativeCurrency, wrappedCurrency)[0]
  return token
}
