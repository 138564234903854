import { Interface } from '@ethersproject/abi'
import { TransactionResponse } from '@ethersproject/providers'
import { TDappData } from 'constants/settings/types/TNpcs'
import { useAppSettings } from 'providers/AppSettingsProvider/AppSettingsProvider'
import React, { useState, useMemo } from 'react'
import styled from 'styled-components'
import { abi as IUniswapV2PairABI } from '../../constants/abis/contracts/IUniswapV2Pair.json'
import { VAULT_TOKEN_POOLS } from '../../constants/vaults'
import { useActiveWeb3React } from '../../hooks'
import useBlockchain from '../../hooks/useBlockchain'
import { useMasterVaultContract } from '../../hooks/useContract'
import useEligibleVaultTokensPools from '../../hooks/useEligibleVaultTokensPools'
import { useMultipleContractSingleData } from '../../state/multicall/hooks'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { toV2LiquidityToken } from '../../state/user/hooks'
import { TYPE, CloseIcon } from '../../theme'
import { calculateGasMargin } from '../../utils'
import { ButtonError } from '../Button'
import { AutoColumn } from '../Column'
import Modal from '../Modal'
import { SubmittedView, LoadingView } from '../ModalViews'
import { RowBetween } from '../Row'

const PAIR_INTERFACE = new Interface(IUniswapV2PairABI)

// TODO: This is just a band-aid for now
// and should be removed when a better fix is implemented
const DISABLED_PIDS = [10, 11]

const ContentWrapper = styled(AutoColumn)`
  width: 100%;
  padding: 1rem;
`
interface ClaimModalProps {
  isOpen: boolean
  onDismiss: () => void
  dappData: TDappData
}

export default function ClaimModal({ isOpen, onDismiss, dappData }: ClaimModalProps) {
  const { account, chainId } = useActiveWeb3React()
  const { settings } = useAppSettings()
  const blockchain = useBlockchain()
  const vaultTokensSettings = chainId ? null : undefined

  // monitor call to help UI loading state
  const addTransaction = useTransactionAdder()
  const [hash, setHash] = useState<string | undefined>()
  const [attempting, setAttempting] = useState(false)
  const [failed, setFailed] = useState<boolean>(false)

  function wrappedOnDismiss() {
    setHash(undefined)
    setAttempting(false)
    setFailed(false)
    onDismiss()
  }

  const masterVault = useMasterVaultContract()
  const stakingPools = useMemo(() => (chainId ? VAULT_TOKEN_POOLS[chainId] : []), [chainId])?.filter(
    pool => !DISABLED_PIDS.includes(pool.pid)
  )

  const liquidityTokenAddresses = useMemo(
    () =>
      stakingPools
        ? stakingPools.map(item => {
            return blockchain && chainId && item && dappData.factoryAddress && dappData.initHash
              ? toV2LiquidityToken(
                  dappData.factoryAddress,
                  dappData.initHash,
                  item.tokens,
                  settings.liquidityPair.symbol ?? 'GAME-LP',
                  settings.liquidityPair.name ?? 'GAME LP'
                )?.address
              : undefined
          })
        : [],
    [blockchain, chainId, stakingPools, settings]
  ).filter(address => address !== undefined)

  const balanceResults = useMultipleContractSingleData(liquidityTokenAddresses, PAIR_INTERFACE, 'balanceOf', [
    masterVault?.address
  ])

  const [claimFrom, claimTo] = useEligibleVaultTokensPools(stakingPools, balanceResults)

  const rewardsAreClaimable = claimFrom.length > 0 && claimTo.length > 0

  async function onClaimRewards() {
    if (masterVault) {
      setAttempting(true)

      try {
        const estimatedGas = await masterVault.estimateGas.convertMultiple(claimFrom, claimTo)

        await masterVault
          .convertMultiple(claimFrom, claimTo, {
            gasLimit: calculateGasMargin(estimatedGas)
          })
          .then((response: TransactionResponse) => {
            addTransaction(response, {
              summary: `Claim ??? rewards`
            })
            setHash(response.hash)
          })
          .catch((error: any) => {
            setAttempting(false)
            if (error?.code === -32603) {
              setFailed(true)
            }
            console.log(error)
          })
      } catch (error) {
        setAttempting(false)
        setFailed(true)
        console.log(error)
      }
    }
  }

  let error: string | undefined
  if (!account) {
    error = 'Connect Wallet'
  }

  return (
    <Modal isOpen={isOpen} onDismiss={wrappedOnDismiss} maxHeight={90}>
      {!attempting && !hash && !failed && (
        <ContentWrapper gap="lg">
          <RowBetween>
            <TYPE.mediumHeader> Claim</TYPE.mediumHeader>
            <CloseIcon onClick={wrappedOnDismiss} />
          </RowBetween>
          <TYPE.body fontSize={32} style={{ textAlign: 'center' }}></TYPE.body>
          {rewardsAreClaimable && (
            <>
              <TYPE.body fontSize={14} style={{ textAlign: 'center' }}>
                When you claim rewards, collected LP fees will be used to market buy {dappData.governanceToken?.symbol}.
                <br />
                <br />
                The purchased {dappData.governanceToken?.symbol} tokens will then be distributed to the ??? stakers as a
                reward.
              </TYPE.body>
              <ButtonError disabled={!!error} error={!!error} onClick={onClaimRewards}>
                {error ?? 'Claim'}
              </ButtonError>
            </>
          )}
          {!rewardsAreClaimable && (
            <TYPE.body fontSize={14} style={{ textAlign: 'center' }}>
              There are no trading fee rewards available
              <br />
              to claim right now.
              <br />
              <br />
              Please wait a little bit and then check back here again.
            </TYPE.body>
          )}
        </ContentWrapper>
      )}
      {attempting && !hash && !failed && (
        <LoadingView onDismiss={wrappedOnDismiss}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.body fontSize={20}>Claiming ??? rewards</TYPE.body>
          </AutoColumn>
        </LoadingView>
      )}
      {hash && !failed && (
        <SubmittedView onDismiss={wrappedOnDismiss} hash={hash}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.largeHeader>Transaction Submitted</TYPE.largeHeader>
            <TYPE.body fontSize={20}>Claimed {dappData.governanceToken?.symbol}!</TYPE.body>
          </AutoColumn>
        </SubmittedView>
      )}
      {!attempting && !hash && failed && (
        <ContentWrapper gap="sm">
          <RowBetween>
            <TYPE.mediumHeader>Error!</TYPE.mediumHeader>
            <CloseIcon onClick={wrappedOnDismiss} />
          </RowBetween>
          <TYPE.subHeader style={{ textAlign: 'center' }}>
            Your transaction couldn&apos;t be submitted.
            <br />
            You may have to increase your Gas Price (GWEI) settings!
          </TYPE.subHeader>
        </ContentWrapper>
      )}
    </Modal>
  )
}
