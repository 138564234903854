import { TransactionResponse } from '@ethersproject/providers'
import { ButtonConfirmed, ButtonError } from 'components/Button'
import { AutoColumn } from 'components/Column'
import CurrencyInputPanel from 'components/CurrencyInputPanel'
import Modal from 'components/Modal'
import { LoadingView, SubmittedView } from 'components/ModalViews'
import ProgressCircles from 'components/ProgressSteps'
import { RowBetween } from 'components/Row'
import { TDappData } from 'constants/settings/types/TNpcs'
import { Contract } from 'ethers'
import { useTokenExchangeContract, useTokenContract } from 'hooks/useContract'
import { useState, useCallback } from 'react'
import React from 'react'
import { useTokenBalance } from 'state/wallet/hooks'
import styled from 'styled-components'
import { TYPE, CloseIcon } from 'theme'
import { useTokenExchangeInfo } from './useTokenExchangeInfo'
import { useActiveWeb3React } from '../../hooks'
import { useApproveCallback, ApprovalState } from '../../hooks/useApproveCallback'
import useTransactionDeadline from '../../hooks/useTransactionDeadline'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { calculateGasMargin } from '../../utils'
import { Token } from '@/lib/sdk/index'

const ContentWrapper = styled(AutoColumn)`
  width: 100%;
  padding: 1rem;
`
interface TokenExchangeModalProps {
  isOpen: boolean
  onDismiss: () => void
  dappData: TDappData
}

export default function TokenExchange({ isOpen, onDismiss, dappData }: TokenExchangeModalProps) {
  const { account, library } = useActiveWeb3React()
  const userDepositedTokenBalance = useTokenBalance(account ?? undefined, dappData.tokenExchange?.tokenToDeposit)

  // Track and parse user input
  const [typedValue, setTypedValue] = useState('')
  const { parsedAmount, error } = useTokenExchangeInfo(
    typedValue,
    dappData.tokenExchange?.tokenToDeposit as Token,
    userDepositedTokenBalance
  )

  // State for pending and submitted transaction views
  const addTransaction = useTransactionAdder()
  const [attempting, setAttempting] = useState<boolean>(false)
  const [hash, setHash] = useState<string | undefined>()
  const [failed, setFailed] = useState<boolean>(false)

  const tokenExchange = useTokenExchangeContract(dappData)

  if (!dappData.tokenExchange) {
    throw new Error('Token Exchange address not found')
  }

  // Approval data for stake
  const deadline = useTransactionDeadline()
  const [signatureData, setSignatureData] = useState<{
    v: number
    r: string
    s: string
    deadline: number
  } | null>(null)
  const depositedToken = dappData.tokenExchange.tokenToDeposit
  const [approval, approveCallback] = useApproveCallback(parsedAmount, dappData.tokenExchange.contractAddress)
  const depositedTokenContract: Contract | null = useTokenContract(depositedToken.address)

  async function onDeposit() {
    setAttempting(true)
    if (tokenExchange && parsedAmount && deadline) {
      if (approval === ApprovalState.APPROVED) {
        const formattedAmount = `0x${parsedAmount.raw.toString(16)}`
        try {
          const estimatedGas = await tokenExchange.estimateGas.exchangeTokens(formattedAmount)

          const response: TransactionResponse = await tokenExchange.exchangeTokens(formattedAmount, {
            gasLimit: calculateGasMargin(estimatedGas)
          })

          addTransaction(response, {
            summary: `Exchanged Token`
          })
          setHash(response.hash)
        } catch (error) {
          setAttempting(false)
          setFailed(true)
          console.error('Exchange Error:', error)
        }
      } else {
        setAttempting(false)
        throw new Error('Attempting to exchange tokens without approval or a signature. Please contact support.')
      }
    }
  }

  // Wrapped onUserInput to clear signatures
  const onUserInput = useCallback((typedValue: string) => {
    setSignatureData(null)
    setTypedValue(typedValue)
  }, [])

  // Approval callback
  async function onAttemptToApprove() {
    if (!depositedTokenContract || !library || !deadline) throw new Error('Missing dependencies')
    if (!parsedAmount) throw new Error('Missing token amount')

    return approveCallback()
  }

  const wrappedOnDismiss = useCallback(() => {
    setHash(undefined)
    setAttempting(false)
    setFailed(false)
    onDismiss()
  }, [onDismiss])

  // Render the modal content
  return (
    <Modal isOpen={isOpen} onDismiss={wrappedOnDismiss} maxHeight={90}>
      {!attempting && !hash && !failed && (
        <ContentWrapper gap="lg">
          <RowBetween>
            <TYPE.mediumHeader>Deposit</TYPE.mediumHeader>
            <CloseIcon onClick={wrappedOnDismiss} />
          </RowBetween>

          <CurrencyInputPanel
            value={typedValue}
            onUserInput={onUserInput}
            onMax={() => ({})}
            showMaxButton={false}
            currency={depositedToken}
            pair={null}
            label={''}
            disableCurrencySelect={true}
            customBalanceText={'Available to deposit: '}
            id="token-exchange-input"
            dappData={dappData}
          />

          <RowBetween>
            <ButtonConfirmed
              mr="0.5rem"
              onClick={onAttemptToApprove}
              confirmed={approval === ApprovalState.APPROVED || signatureData !== null}
              disabled={approval !== ApprovalState.NOT_APPROVED || signatureData !== null}
            >
              Approve
            </ButtonConfirmed>
            <ButtonError
              disabled={!!error || (signatureData === null && approval !== ApprovalState.APPROVED)}
              error={!!error && !!parsedAmount}
              onClick={onDeposit}
            >
              {error ?? 'Deposit'}
            </ButtonError>
          </RowBetween>
          <ProgressCircles steps={[approval === ApprovalState.APPROVED || signatureData !== null]} disabled={true} />
        </ContentWrapper>
      )}
      {attempting && !hash && !failed && (
        <LoadingView onDismiss={wrappedOnDismiss}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.largeHeader>Exchanging Tokens</TYPE.largeHeader>
            <TYPE.body fontSize={20}>
              {parsedAmount?.toSignificant(4)} {depositedToken.symbol}{' '}
            </TYPE.body>
          </AutoColumn>
        </LoadingView>
      )}
      {attempting && hash && !failed && (
        <SubmittedView onDismiss={wrappedOnDismiss} hash={hash}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.largeHeader>Transaction Submitted</TYPE.largeHeader>
            <TYPE.body fontSize={20}>
              Exchanged {parsedAmount?.toSignificant(4)} {depositedToken.symbol}{' '}
            </TYPE.body>
          </AutoColumn>
        </SubmittedView>
      )}
      {!attempting && !hash && failed && (
        <ContentWrapper gap="sm">
          <RowBetween>
            <TYPE.mediumHeader>Error!</TYPE.mediumHeader>
            <CloseIcon onClick={wrappedOnDismiss} />
          </RowBetween>
          <TYPE.subHeader style={{ textAlign: 'center' }}>
            Your transaction couldn&apos;t be submitted.
            <br />
            You may have to increase your Gas Price (GWEI) settings!
          </TYPE.subHeader>
        </ContentWrapper>
      )}
    </Modal>
  )
}
