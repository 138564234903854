import { TDappData } from 'constants/settings/types/TNpcs'
import { useAppSettings } from 'providers/AppSettingsProvider/AppSettingsProvider'
import { useMemo } from 'react'
import { wrappedCurrency } from './wrappedCurrency'
import { PairState, usePairs } from '../data/Reserves'
import { useActiveWeb3React } from '../hooks'
import getToken from './getToken'
import { Currency, currencyEquals, JSBI, Price, Token } from '@/lib/sdk/index'

/**
 * Returns the price in USDC of the input currency
 * @param currency currency to compute the USDC price of
 */
export default function useUSDCPrice(dappData: TDappData, currency?: Currency): Price | undefined {
  const { chainId } = useActiveWeb3React()
  const { settings } = useAppSettings()
  const wrapped = wrappedCurrency(currency, chainId, settings.blockchainSettings.currency, settings.wrappedCurrency)
  const usdcTicker = 'USDC'
  const usdcToken: Token | undefined = getToken(chainId, usdcTicker, settings.wrappedCurrency, settings.chainTokenList)

  const tokenPairs: [Currency | undefined, Currency | undefined][] = useMemo(
    () => [
      [
        chainId && wrapped && currencyEquals(settings.wrappedCurrency, wrapped) ? undefined : currency,
        chainId ? settings.wrappedCurrency : undefined
      ],
      [usdcToken && wrapped?.equals(usdcToken) ? undefined : wrapped, usdcToken],
      [chainId ? settings.wrappedCurrency : undefined, usdcToken]
    ],
    [chainId, currency, wrapped]
  )
  const [[ethPairState, ethPair], [usdcPairState, usdcPair], [usdcEthPairState, usdcEthPair]] = usePairs(
    tokenPairs,
    dappData
  )

  return useMemo(() => {
    if (!currency || !wrapped || !chainId) {
      return undefined
    }
    // handle weth/eth
    if (wrapped.equals(settings.wrappedCurrency)) {
      if (usdcPair && usdcToken) {
        const price = usdcPair.priceOf(settings.wrappedCurrency)
        return new Price(currency, usdcToken, price.denominator, price.numerator)
      } else {
        return undefined
      }
    }
    // handle usdc
    if (usdcToken && wrapped.equals(usdcToken)) {
      return new Price(usdcToken, usdcToken, '1', '1')
    }

    const ethPairETHAmount = ethPair?.reserveOf(settings.wrappedCurrency)
    const ethPairETHUSDCValue: JSBI =
      ethPairETHAmount && usdcEthPair
        ? usdcEthPair.priceOf(settings.wrappedCurrency).quote(ethPairETHAmount).raw
        : JSBI.BigInt(0)

    // all other tokens
    // first try the usdc pair
    if (
      usdcToken &&
      usdcPairState === PairState.EXISTS &&
      usdcPair &&
      usdcPair.reserveOf(usdcToken).greaterThan(ethPairETHUSDCValue)
    ) {
      const price = usdcPair.priceOf(wrapped)
      return new Price(currency, usdcToken, price.denominator, price.numerator)
    }
    if (
      usdcToken &&
      ethPairState === PairState.EXISTS &&
      ethPair &&
      usdcEthPairState === PairState.EXISTS &&
      usdcEthPair
    ) {
      if (
        usdcEthPair.reserveOf(usdcToken).greaterThan('0') &&
        ethPair.reserveOf(settings.wrappedCurrency).greaterThan('0')
      ) {
        const ethUsdcPrice = usdcEthPair.priceOf(usdcToken)
        const currencyEthPrice = ethPair.priceOf(settings.wrappedCurrency)
        const usdcPrice = ethUsdcPrice.multiply(currencyEthPrice).invert()
        return new Price(currency, usdcToken, usdcPrice.denominator, usdcPrice.numerator)
      }
    }
    return undefined
  }, [chainId, currency, ethPair, ethPairState, usdcEthPair, usdcEthPairState, usdcPair, usdcPairState, wrapped])
}
