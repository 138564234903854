import { MouseoverTooltip } from 'components/Tooltip'
import { BlockChainPollIntervalSettings } from 'constants/settings'
import { useAppConstant } from 'providers/AppSettingsProvider/hooks/useAppConstant'
import React from 'react'
import { Box, Text } from 'rebass'
import { useBlockNumber } from 'state/application/hooks'
import styled from 'styled-components'
import { BlockchainIcon } from '../BlockChainMenuIcon/BlockChainIcon'
import { SettingIcon } from '../SettingsMenuIcon/SettingIcon'

export const getSeason = (date: Date): string => {
  const month = date.getMonth()
  let season: string

  if (month >= 2 && month <= 4) {
    season = 'Spring' // March, April, May
  } else if (month >= 5 && month <= 7) {
    season = 'Summer' // June, July, August
  } else if (month >= 8 && month <= 10) {
    season = 'Fall' // September, October, November
  } else {
    season = 'Winter' // December, January, February
  }

  const day = date.getDate()
  const weekday = date.toLocaleDateString('en-US', { weekday: 'short' })

  return `${season} ${day} (${weekday})`
}

const FloatingMenuContainer = styled(Box)`
  position: absolute;
  right: 10px;
  top: 20px;
`

const FloatingMenuInner = styled(Box)`
  display: flex;
  flex-direction: row;
`

// Add a fun gradient background
const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 15px;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.25);
`

// Make the date and time text more "game-like" by bolding and adjusting the font
const DateTimeText = styled(Text)`
  font-size: 1.2rem;
  color: #fff;
  margin-bottom: 5px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
`

const BlockNumberText = styled(Text)`
  font-size: 1.2rem;
  font-family: 'Comic Sans MS', 'Arial', sans-serif; // A playful, game-style font
  color: #4e342e;
  margin-bottom: 5px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  &::before {
    content: '⦿';
    font-size: 1.5rem;
    color: #ff5722;
    margin-right: 8px;
  }
`

export const Menu: React.FC = () => {
  const blocknumber = useBlockNumber()
  const currentDate = new Date()
  const currentSeason = getSeason(currentDate)
  const { settings } = useAppConstant()
  return (
    <FloatingMenuContainer>
      <FloatingMenuInner>
        <BlockchainIcon />
        <SettingIcon />
        <Container>
          <Box mb={2}>
            <DateTimeText>{currentSeason}</DateTimeText>
          </Box>
          <MouseoverTooltip
            text={`Block number is updated ~ ${Math.round(
              BlockChainPollIntervalSettings[settings.blockchainSettings.chainId] / 1000
            )} seconds based on the latest mined block. Please note that due to network conditions and window activity, there may be a slight delay in updates.`}
          >
            <BlockNumberText>{blocknumber}</BlockNumberText>
          </MouseoverTooltip>
        </Container>
      </FloatingMenuInner>
    </FloatingMenuContainer>
  )
}
